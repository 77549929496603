import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axiosClient from '../utils/api';

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
const WS_TOKEN_ENDPOINT = '/auth/get-ws-token';

const WebSocketContext = createContext(null);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export const WebSocketProvider = ({ children }) => {
  const auth = useSelector((state) => state.webauth);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const messageListenersRef = useRef(new Set());

  const getWebSocketToken = async () => {
    try {
      const response = await axiosClient.request({
        method: 'GET',
        url: WS_TOKEN_ENDPOINT,
      });
      
      if (response.data.status === 1) {
        return response.data.response.token;
      }
      throw new Error('Failed to get WebSocket token');
    } catch (err) {
      console.error('Error getting WebSocket token:', err);
      setError(err.message);
      return null;
    }
  };

  const connect = useCallback(async () => {
    // Clear any existing reconnection timeout
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }

    // If already connected, don't try to connect again
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      return;
    }

    try {
      const wsToken = await getWebSocketToken();
      if (!wsToken) {
        console.error('No WebSocket token received');
        return;
      }

      // Close existing connection if any
      if (wsRef.current) {
        wsRef.current.close();
      }

      const wsUrl = `${WEBSOCKET_URL}?token=${wsToken}`;
      wsRef.current = new WebSocket(wsUrl);

      wsRef.current.onopen = () => {
        setIsConnected(true);
        setError(null);
      };

      wsRef.current.onclose = (event) => {
        setIsConnected(false);
        
        // Only attempt to reconnect if the user is still logged in
        if (auth.webloggedInUser) {
          reconnectTimeoutRef.current = setTimeout(connect, 5000);
        }
      };

      wsRef.current.onerror = (event) => {
        console.error('WebSocket error:', event);
        setError('WebSocket connection error');
      };

      wsRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.action === 'NEW_MESSAGE') {
            messageListenersRef.current.forEach(listener => {
              listener(data.message);
            });
          }
        } catch (err) {
          console.error('Error parsing WebSocket message:', err);
        }
      };
    } catch (err) {
      console.error('Error in connect function:', err);
      setError(err.message);
    }
  }, [auth.webloggedInUser]);

  const disconnect = useCallback(() => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }
    
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    setIsConnected(false);
  }, []);

  const addMessageListener = useCallback((listener) => {
    messageListenersRef.current.add(listener);
    return () => {
      messageListenersRef.current.delete(listener);
    };
  }, []);

  useEffect(() => {
    if (auth.webloggedInUser) {
      connect();
    } else {
      disconnect();
    }

    return () => {
      disconnect();
    };
  }, [auth.webloggedInUser, connect, disconnect]);

  const value = {
    isConnected,
    error,
    addMessageListener
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};
