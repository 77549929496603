import axiosClient from "../../../../utils/api";

// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/state";

export const getAllStates = async () => {
  const URL = `${URI}/getState`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const addState = async (payload) => {
  const URL = `${URI}/addState`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const updateState = async (payload) => {
  const URL = `${URI}/updateState`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const deleteState = async (payload) => {
  const URL = `${URI}/deleteState`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    let { data } = response;
    return { id: payload.id, ...data };
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};
