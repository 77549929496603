import React, { useEffect, useState } from "react";
import ProfileMail from "./../../../../../assets/images/profile-email.svg";
import ProfilePhone from "./../../../../../assets/images/profile-phone.svg";
import ProfilePassword from "./../../../../../assets/images/profile-password.svg";
import LoginSession from "./../../../../../assets/images/login-session.svg";
import TwoStep from "./../../../../../assets/images/two-step.svg";
import SpotifyAccount from "./../../../../../assets/images/spotify.svg";
import GoogleAccount from "./../../../../../assets/images/google-account.svg";
import ProfiletextboxUser from "./../../../../../assets/images/profile-textbox-user.svg";
import TwitterIcon from "./../../../../../assets/images/twitter.svg";
import YoutubeIcon from "./../../../../../assets/images/youtube.svg";
import TiktokIcon from "./../../../../../assets/images/tiktok.svg";
import InstagramIcon from "./../../../../../assets/images/instagram.svg";
import { Grid } from "@mui/material";
import {
  ModalChangeEmail,
  ModalChangePhoneNumber,
  ModalChangeTikTok,
  ModalChangeTwitter,
  ModalChangeUsername,
  ModalChangeYouTube,
  ModalGoogleAccount,
  ModalLoginSessions,
  ModalProfilePassword,
  ModalTwoStepAuth,
} from "../../../../molecules";
import { ProfileButtons, ProfileSectionWrapper } from "../../../../atoms";

import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../store/slices/common/modal";
import ModalChangeInstagram from "../../../../molecules/web/myProfile/account/ModalChangeInstagram";
import VerifiedIcon from "./../../../../../assets/images/verified.svg";

const ProfileAccount = () => {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.me);
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    setSocialLinks(me?.socialLinks || []);
  }, [me]);


  const extractUsernameFromUrl = (url, platform) => {
    switch (platform) {
      case "instagram":
        return url.split("instagram.com/").pop()?.split("/")[0] || "";
      case "twitter":
        return url.split("x.com/").pop()?.split("/")[0] || "";
      case "youtube":
        return url.split("youtube.com/").pop()?.split("/")[0] || "";
      case "tiktok":
        return url.split("@").pop()?.split("/")[0] || "";
      default:
        return "";
    }
  };

  const instagramUrl = extractUsernameFromUrl(
    socialLinks.find((link) => link.type === "instagram")?.url || "",
    "instagram"
  );
  const twitterUrl = extractUsernameFromUrl(
    socialLinks.find((link) => link.type === "twitter")?.url || "",
    "twitter"
  );
  const youtubeUrl = extractUsernameFromUrl(
    socialLinks.find((link) => link.type === "youtube")?.url || "",
    "youtube"
  );
  const tiktokUrl = extractUsernameFromUrl(
    socialLinks.find((link) => link.type === "tiktok")?.url || "",
    "tiktok"
  );

  const handleOpenDialog = (name) => {
    if (name === "change-username") {
      dispatch(
        openModal({
          title: "CHANGE USERNAME",
          content: <ModalChangeUsername />,
        })
      );
    }
    if (name === "change-email") {
      dispatch(
        openModal({
          title: "CHANGE EMAIL",
          content: <ModalChangeEmail />,
        })
      );
    }
    if (name === "change-phone") {
      dispatch(
        openModal({
          title: "CHANGE PHONE NUMBER",
          content: <ModalChangePhoneNumber />,
        })
      );
    }
    if (name === "google-account") {
      dispatch(
        openModal({
          title: "GOOGLE ACCOUNT",
          content: <ModalGoogleAccount />,
        })
      );
    }
    if (name === "profile-password") {
      dispatch(
        openModal({
          title: "CHANGE PASSWORD",
          content: <ModalProfilePassword />,
        })
      );
    }
    if (name === "login-sessions") {
      dispatch(
        openModal({
          title: "LOGIN SESSIONS",
          content: <ModalLoginSessions />,
        })
      );
    }
    if (name === "two-step-auth") {
      dispatch(
        openModal({
          title: "TWO STEP AUTHENTICATION",
          content: <ModalTwoStepAuth />,
          hasCloseBtn: true,
        })
      );
    }
    if (name === "change-Instagram") {
      dispatch(
        openModal({
          title: "Instagram",
          content: <ModalChangeInstagram />,
          hasCloseBtn: true,
        })
      );
    }
    if (name === "change-Tiktok") {
      dispatch(
        openModal({
          title: "Tiktok",
          content: <ModalChangeTikTok />,
          hasCloseBtn: true,
        })
      );
    }
    if (name === "change-Twitter") {
      dispatch(
        openModal({
          title: "Twitter",
          content: <ModalChangeTwitter />,
          hasCloseBtn: true,
        })
      );
    }
    if (name === "change-Youtube") {
      dispatch(
        openModal({
          title: "Youtube",
          content: <ModalChangeYouTube />,
          hasCloseBtn: true,
        })
      );
    }
  };

  const handleRedirectSpotify = () => {
    // Connect Spotify
    if (!me?.spotify?.displayName) {
      var state = 123;
      var scope = ["user-read-private, user-read-email"];
      var url = "https://accounts.spotify.com/authorize";
      url += "?response_type=token";
      // eslint-disable-next-line no-undef
      url += "&client_id=" + encodeURIComponent(process.env.REACT_APP_SPOTIFY_CLIENT_ID);
      url += "&scope=" + encodeURIComponent(scope.join(","));
      // eslint-disable-next-line no-undef
      url += "&redirect_uri=" + encodeURIComponent(process.env.REACT_APP_SPOTIFY_CALLBACK_URL);
      url += "&state=" + encodeURIComponent(state);
      window.location = `${url}`;
    }
  };



  return (
    <Grid container spacing={2} className="fans-profile mt-3">
      <Grid item xs={12} md={12} lg={12} className="mb-4">
        <div className="bg-white rounded-2xl py-3 px-5 h-calc-200">
          <h3 className="font-semibold text-darkblue font-24 mb-3">
            Account Info
          </h3>

          <ProfileSectionWrapper title={"Additional Info"}>
            <Grid container spacing={2} className="fans-profile account-info">
              <ProfileButtons
                endAdornment={
                  <img
                    src={ProfiletextboxUser}
                    alt="Profile User"
                    onClick={() => handleOpenDialog("change-username")}
                  />
                }
                title={"Username"}
                hasValue={me && me?.username}
                value={me?.username ? `@${me?.username}` : ""}
              />
              <ProfileButtons
                endAdornment={
                  <>
                  {me?.isIdentityVerified && <img src={VerifiedIcon} alt="Verified" />}
                  <img
                    src={ProfileMail}
                    alt="Profile Mail"
                    onClick={() => handleOpenDialog("change-email")}
                  />
                  
                  </>
                }
                title={"Email"}
                hasValue={me && me?.email}
                value={me?.email}
              />
              <ProfileButtons
                endAdornment={
                  <img
                    src={ProfilePhone}
                    alt="Profile Phone"
                    onClick={() => handleOpenDialog("change-phone")}
                  />
                }
                title={"Phone Number"}
                hasValue={me && me?.phone}
                value={me?.phone ? me?.phone?.replace("-", " ") : ""}
              />
            </Grid>
          </ProfileSectionWrapper>

          {/* <ProfileSectionWrapper title={"Linked accounts"}>
            <Grid container spacing={2} className="fans-profile account-info">
              {/ * <ProfileButtons endAdornment={<img src={TwitterAccount} alt='Twitter account' onClick={() => handleOpenDialog("twitter-account")} />} title={"Twitter account"} hasOneValue={false} /> * /}
              {me?.googleId === null && (
                <ProfileButtons
                  endAdornment={
                    <img
                      src={GoogleAccount}
                      alt="Google account"
                      onClick={() => handleOpenDialog("google-account")}
                    />
                  }
                  title={"Google account"}
                  hasValue={me && me?.googleId}
                  hasOneValue={false}
                />
              )}
              <ProfileButtons
                endAdornment={
                  <img
                    src={SpotifyAccount}
                    alt="Spotify account"
                    onClick={() => handleRedirectSpotify()}
                  />
                }
                title={"Spotify"}
                hasOneValue={true}
                value={
                  me?.spotify?.displayName ? `@${me?.spotify?.displayName}` : ""
                }
              />
            </Grid>
          </ProfileSectionWrapper> */}

          {me?.userType === 'creator' && <ProfileSectionWrapper title={"Social accounts"}>
            <Grid container spacing={2} className="fans-profile account-info">
              <ProfileButtons
                endAdornment={
                  <img
                    src={YoutubeIcon}
                    alt="Profile Youtube"
                    onClick={() => handleOpenDialog("change-Youtube")}
                  />
                }
                title={"Youtube"}
                hasValue={!!youtubeUrl}
                value={youtubeUrl}
              />
              <ProfileButtons
                endAdornment={
                  <img
                    src={TiktokIcon}
                    alt="Profile Tiktok"
                    onClick={() => handleOpenDialog("change-Tiktok")}
                  />
                }
                title={"Tiktok"}
                hasValue={!!tiktokUrl}
                value={tiktokUrl}
              />
              <ProfileButtons
                endAdornment={
                  <img
                    src={TwitterIcon}
                    alt="Profile Twitter"
                    onClick={() => handleOpenDialog("change-Twitter")}
                  />
                }
                title={"Twitter"}
                hasValue={!!twitterUrl}
                value={twitterUrl}
              />
              <ProfileButtons
                endAdornment={
                  <img
                    src={InstagramIcon}
                    alt="Profile Instagram"
                    onClick={() => handleOpenDialog("change-Instagram")}
                  />
                }
                title={"Instagram"}
                hasValue={!!instagramUrl}
                value={instagramUrl}
              />
            </Grid>
          </ProfileSectionWrapper>}

          <ProfileSectionWrapper title={"Security"}>
            <Grid container spacing={2} className="fans-profile account-info">
              {me?.isGoogleLogin === false && me?.isAppleLogin === false && (
                <ProfileButtons
                  endAdornment={
                    <img
                      src={ProfilePassword}
                      alt="Profile Password"
                      onClick={() => handleOpenDialog("profile-password")}
                    />
                  }
                  title={"Profile Password"}
                  hasOneValue={false}
                />
              )}
              <ProfileButtons
                endAdornment={
                  <img
                    src={LoginSession}
                    alt="Login Session"
                    onClick={() => handleOpenDialog("login-sessions")}
                  />
                }
                title={"Login Sessions"}
                hasOneValue={false}
              />
              <ProfileButtons
                endAdornment={
                  <img
                    src={TwoStep}
                    alt="Two Step Authentication"
                    onClick={() => handleOpenDialog("two-step-auth")}
                  />
                }
                title={"Two Step Authentication"}
                hasOneValue={false}
              />
              {/* <ProfileButtons endAdornment={<img src={Passwordless} alt='Passwordless Sign In' onClick={() => handleOpenDialog("passwordless-sign-in")} />} title={"Passwordless Sign In"} hasOneValue={false} /> */}
            </Grid>
          </ProfileSectionWrapper>
        </div>
      </Grid>
    </Grid>
  );
};

export default ProfileAccount;
