import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { closeModal } from "../../../../../store/slices/common/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addFolderAction,
  getFolderAction,
} from "../../../../../store/middlewares/web/folders/folders";
import { addToBookMarks } from "../../../../../store/middlewares/web/post/posts";
import FolderSelector from "../../common/FolderSelector";

const ModalAddBookMark = ({ postId = 0, onlyAdd = false }) => {
  const { folders } = useSelector((state) => state?.folders);
  const [selectedFolder, setSelectedFolder] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onlyAdd) {
      dispatch(getFolderAction());
    }
  }, [onlyAdd, dispatch]);

  const handleFolderSelect = (folderId) => {
    setSelectedFolder(folderId);
  };

  const handleCreateFolder = async (folderName) => {
    const result = await dispatch(addFolderAction({ onlyAdd, folderName })).unwrap();
    if (result?.response?.folder) {
      await dispatch(getFolderAction());
      return result.response.folder;
    }
    throw new Error('Failed to create folder');
  };

  const handleAddToBookMark = () => {
    if (postId !== 0 && selectedFolder) {
      const reqObj = {
        postId: postId,
        folderId: selectedFolder,
      };
      dispatch(addToBookMarks(reqObj));
      dispatch(closeModal());
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <FolderSelector
        folders={folders || []}
        selectedFolderId={selectedFolder}
        onFolderSelect={handleFolderSelect}
        onCreateFolder={handleCreateFolder}
      />
      
      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto px-12"
          onClick={handleAddToBookMark}
          disabled={!selectedFolder}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ModalAddBookMark;
