import axiosClient from "../../../../utils/api";

export const updateAccount = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/account/updateAccount",
      method: "post",
      data: { userData: payload },
    });
    return response.data;
  } catch (error) {
    console.log("Error at Update Account Service", error);
    throw error;
  }
};

export const getAllSessions = async () => {
  try {
    const response = await axiosClient.request({
      url: "/account/getSession",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get All Sessions Service", error);
    throw error;
  }
};

export const deleteSessions = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/account/deleteSession",
      method: "post",
      data: payload,
    });
    let tempArray = payload.data.includes(",")
      ? payload.data.split(",")
      : [payload.data];
    let { data } = response;
    return { ids: tempArray, ...data };
  } catch (error) {
    console.log("Error at Delete Sessions Service", error);
    throw error;
  }
};

export const changePassword = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/account/changePassword",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Change Password Service", error);
    throw error;
  }
};

export const connectAccount = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/account/connectAccount",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Connect Account Service", error);
    throw error;
  }
};

export const activate2StepAuth = async () => {
  try {
    const response = await axiosClient.request({
      url: "/account/activateApp2Step",
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Activate Service", error);
    throw error;
  }
};

export const verifyTOTPFirstTime = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/account/verifyOtpOnce",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Verify TOTP  Service", error);
    throw error;
  }
};

export const connectSpotify = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/account/connectSpotify",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Connect Spotify Service", error);
    throw error;
  }
};

export const getTelegramBotLink = async () => {
  try {
    const response = await axiosClient.request({
      url: "/profile/telegramBotConnectLink",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Telegram Bot Link Service", error);
    throw error;
  }
};

export const updateMeEmail = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/account/email",
      method: "put",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Update Email Service", error);
    throw error;
  }
};

export const confirmChangeEmail = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/account/confirm-change-email",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Confirm Change Email Service", error);
    throw error;
  }
};
