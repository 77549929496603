/* eslint-disable no-undef */
/**
 * @fileOverview This file contains the axiosClient utility function and related constants for making HTTP requests.
 * @module axiosClient
 */

import axios from "axios";
import { encryptAndStoreToken, getDecryptedToken } from "./CryptoJS";
import LocalStorageService from "./localstorage-services";

axios.defaults.withCredentials = true;
const youOnly = {
  name: "youonly",
  account: `${process.env.REACT_APP_LIVE_URL || "https://web-dev.youonly.io"}`,
  api: `${process.env.REACT_APP_API_URL || "https://api-dev.youonly.io"}`,
  live: `${process.env.REACT_APP_LIVE_URL || "https://web-dev.youonly.io"}`,
  admin: `${
    process.env.REACT_APP_ADMIN_URL || "https://web-dev.youonly.io/admin"
  }`,
};

const localhost = {
  account: "http://localhost:3000",
  admin: "http://localhost:3000/admin",
  api: "https://api-dev.youonly.io", // TODO: Change to localhost:8080 note that this is the api gateway url
};

const path = window.location.href;
const live = !!path.match(youOnly.live);

const apiURL = live ? youOnly.api : localhost.api;
const accountURL = live ? youOnly.account : localhost.account;
const adminURL = live ? youOnly.admin : localhost.admin;

export const API_URL = apiURL;
export const ACCOUNT_URL = accountURL;
const defaultOptions = {
  baseURL: apiURL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    accept: "application/json",
  },
};

const axiosClient = axios.create(defaultOptions);
const axiosAuthClient = axios.create(defaultOptions);

//TOKEN REFRESH --------------------------------------------
const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000;


function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
}


function shouldRefreshToken(token) {
  if (!token) return false;
  const decoded = parseJwt(token);
  if (!decoded) return false;
  
  const expirationTime = decoded.exp * 1000;
  const currentTime = Date.now();
  
  return currentTime + TOKEN_REFRESH_THRESHOLD > expirationTime;
}

let isRefreshing = false;
let refreshSubscribers = [];

function onAccessTokenRefreshed(newAccessToken) {
  refreshSubscribers.forEach((callback) => callback(newAccessToken));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

axiosClient.interceptors.request.use(
  async (config) => {
    const adminToken = JSON.parse(
      localStorage.getItem("user-details-admin")
    )?.token;
    const userDetails = JSON.parse(localStorage.getItem("user-details"));
    const token = userDetails?.token;
    const path = window.location.href;
    
    const currentToken = path.includes(adminURL) ? adminToken : token;
    const decryptedToken = getDecryptedToken(currentToken);

    if (shouldRefreshToken(decryptedToken) && !config.url.includes('/auth/refreshToken')) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshResponse = await axiosAuthClient.post("/auth/refreshToken");
          const { accessToken } = refreshResponse.data.response;
          
          await LocalStorageService.replaceToken(encryptAndStoreToken(accessToken));
          onAccessTokenRefreshed(accessToken);
          config.headers.Authorization = `Bearer ${accessToken}`;
        } catch (error) {
          console.error('Failed to refresh token:', error);

          if (error.response?.status === 401) {
            LocalStorageService.removeFromLocalStorage("user-details");
            LocalStorageService.removeFromLocalStorage("user-details-admin");
            window.location.href = `${ACCOUNT_URL}/login`;
          }
        } finally {
          isRefreshing = false;
        }
      } else {

        await new Promise(resolve => {
          addRefreshSubscriber((newToken) => {
            config.headers.Authorization = `Bearer ${newToken}`;
            resolve();
          });
        });
      }
    } else {
      config.headers.Authorization = `Bearer ${decryptedToken}`;
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle session errors
    if (error.response?.data?.message === "Session is required") {
      try {
        await axiosAuthClient.post("/auth/logout");
      } catch (logoutError) {
        console.error("Error during logout:", logoutError);
      } finally {
        LocalStorageService.removeFromLocalStorage("user-details");
        LocalStorageService.removeFromLocalStorage("user-details-admin");
        window.location.href = `${ACCOUNT_URL}/login`;
      }
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      // Don't redirect if we're already on a registration or login-related path
      const currentPath = window.location.pathname.toLowerCase();
      const authPaths = ['/register', '/login', '/verify', '/reset-password'];
      const isAuthPath = authPaths.some(path => currentPath.includes(path));
      
      if (!isAuthPath) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const refreshResponse = await axiosAuthClient.post("/auth/refreshToken");
            const { accessToken } = refreshResponse.data.response;
            
            await LocalStorageService.replaceToken(encryptAndStoreToken(accessToken));
            onAccessTokenRefreshed(accessToken);
            
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            LocalStorageService.removeFromLocalStorage("user-details");
            LocalStorageService.removeFromLocalStorage("user-details-admin");
            window.location.href = `${ACCOUNT_URL}/login`;
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise((resolve) => {
            addRefreshSubscriber((newToken) => {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              resolve(axios(originalRequest));
            });
          });
        }
      }
    }

    return Promise.reject(error);
  }
);

export { axiosAuthClient };
export default axiosClient;

export const appendFilesToFormData = (formData, files) => {
  if (files) {
    files.forEach((file, index) => {
      let fileType, blob, extension;

      if (file instanceof File) {
        fileType = file.type.startsWith("audio/") ? "audio" : "unknown";
        blob = file;
        extension = file.name.split(".").pop();
      } else if (typeof file === "string" && file.startsWith("data:")) {
        const byteString = atob(file.split(",")[1]);
        const mimeString = file.split(",")[0].split(":")[1].split(";")[0];
        fileType = mimeString.startsWith("video/") ? "video" : "image";

        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteArray[i] = byteString.charCodeAt(i);
        }

        blob = new Blob([byteArray], { type: mimeString });
        extension = fileType === "video" ? "mp4" : "jpg";
      } else {
        console.error("Unsupported file type:", file);
        return;
      }

      formData.append("file", blob, `file-${index}.${extension}`);
    });
  }
  return formData;
};
