import axiosClient from "../../../../utils/api";

export const verifyPhoneNumber = async (phoneNumber) => {
  try {
    const response = await axiosClient.request({
      url: "/profile/phone/verifyPhoneNumber",
      method: "post",
      data: { phoneNumber },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const validateVerificationCode = async (verificationCode) => {
  try {
    const response = await axiosClient.request({
      url: "/profile/phone/validateVerificationCode",
      method: "post",
      data: { verificationCode },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const toggleTwoFactor = async () => {
  try {
    const response = await axiosClient.request({
      url: "/profile/phone/toggleTwoFactor",
      method: "post",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
