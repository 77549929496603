import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

const Datepicker = ({ value, onChange, label, maxDate, slotProps, error, errorMsg, disabled, handleDateChange }) => {
  const defaultMaxDate = dayjs();
 

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        className="w-full"
        value={value}
        onChange={onChange}
        label={label ?? ""}
        maxDate={maxDate || defaultMaxDate}
        slotProps={slotProps}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
