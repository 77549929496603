import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NotificationMessage from "./../../../../../../assets/images/notification-msg.svg";
import NewsLetter from "./../../../../../../assets/images/newsletter.svg";
import ReceiveNotification from "./../../../../../../assets/images/receive-notification.svg";
import ImpSubscription from "./../../../../../../assets/images/imp-subscription.svg";
import ProfileMail from "./../../../../../../assets/images/profile-email.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import keyValueObj from "../../../../../../utils/email-notification-object";
import {
  getNotificationsPreferencesAction,
  updateNotificationsAction,
} from "../../../../../../store/middlewares/web/profile/myProfile/notifications";

const ModalEmailNotifications = () => {
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.notifications);

  const getNotification = useCallback(() => {
    dispatch(getNotificationsPreferencesAction());
  }, [dispatch]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  useEffect(() => {
    const notificationTypes = {
      emailNewPostSummary: "new-post-summary",
      emailNewStream: "new-stream",
      emailUpcomingStreamReminders: "upcoming-stream-reminders",
      emailNewPrivateMessage: "new-private-message",
      emailNewSubscriber: "new-subscriber",
      emailNewSubscriptionFromTrial: "new-subscription-from-trial"
    };

    const checkedItems = Object.entries(notificationTypes)
      .filter(([key]) => items?.[key])
      .map(([, value]) => value);

    setChecked(checkedItems);
  }, [items]);


  const schema = Yup.object({});

  const handleSubmit = (values) => {
    let tempUserData = { ...values };
    let reqObj = {UserData: tempUserData };
    dispatch(updateNotificationsAction(reqObj));
  };

  //initialize Form Object
  const { values, handleBlur, handleChange, setFieldValue } = useFormik({
    initialValues: {
      emailEnabled: items?.emailEnabled
        ? items?.emailEnabled
        : false,
      emailShowFullText: items?.emailShowFullText
        ? items?.emailShowFullText
        : false,
      emailMonthlyNewsletter: items?.emailMonthlyNewsletter
        ? items?.emailMonthlyNewsletter
        : false,
      emailNewPostSummary: items?.emailNewPostSummary
        ? items?.emailNewPostSummary
        : "none",
      emailNewStream: items?.emailNewStream ? items?.emailNewStream : "none",
      emailUpcomingStreamReminders: items?.emailUpcomingStreamReminders
        ? items?.emailUpcomingStreamReminders
        : "none",
      emailNewPrivateMessage: items?.emailNewPrivateMessage
        ? items?.emailNewPrivateMessage
        : "none",
      emailNewSubscriber: items?.emailNewSubscriber
        ? items?.emailNewSubscriber
        : "none",
      emailNewSubscriptionFromTrial: items?.emailNewSubscriptionFromTrial
        ? items?.emailNewSubscriptionFromTrial
        : "none",
      emailReceiveLessNotification: items?.emailReceiveLessNotification
        ? items?.emailReceiveLessNotification
        : false,
      emailImportantSubscriptionNotification:
        items?.emailImportantSubscriptionNotification
          ? items?.emailImportantSubscriptionNotification
          : false,
    },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  const handleCheckboxChange = (e) => {
    // handle Checkbox Change
    let tempArray = [];
    checked?.forEach((item) => {
      tempArray.push(item);
    });
    if (tempArray.includes(e.target.value)) {
      tempArray = tempArray.filter((arr) => arr !== e.target.value);
      let fieldName = keyValueObj[e.target.value];
      setFieldValue(fieldName, "none");
      const updatedValues = { ...values, [fieldName]: "none" };
      handleSubmit(updatedValues);
      Object.keys(updatedValues).forEach((key) => {
        setFieldValue(key, updatedValues[key]);
      });
    } else {
      tempArray.push(e.target.value);
    }
    setChecked(tempArray);
  };

  return (
    //title - EMAIL NOTIFICATIONS
    <>
      <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3">
        <div className="flex items-start gap-4 w-full">
          <img src={ProfileMail} alt="ProfileMail" />
          <div>
            <p className="font-18 font-medium text-darkblue ">
              Email Notifications
            </p>
            <p>
              Get emails to find out what’s going on when you’re not on YouOnly.
              You can turn them off anytime.
            </p>
          </div>
        </div>
        <FormControlLabel
          className="black-border"
          control={
            <Switch
              checked={values.emailEnabled}
              onChange={(e) => {
                handleChange(e);
                setFieldValue("emailEnabled", !values.emailEnabled);
                handleSubmit({
                  ...values,
                  emailEnabled: !values.emailEnabled,
                });
              }}
              onBlur={handleBlur}
              name="emailReceiveLessNotification"
              color="primary"
            />
          }
        />
      </div>

      <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3 mt-3">
        <div className="flex items-start gap-4 w-full">
          <img src={NotificationMessage} alt="Notification Message" />
          <div>
            <p className="font-18 font-medium text-darkblue ">
              Show Full Text of The Message In the Notification Email
            </p>
          </div>
        </div>
        <FormControlLabel
          className="black-border"
          control={
            <Switch
              checked={values.emailShowFullText}
              onChange={(e) => {
                handleChange(e);
                setFieldValue("emailShowFullText", !values.emailShowFullText);
                handleSubmit({
                  ...values,
                  emailShowFullText: !values.emailShowFullText,
                });
              }}
              name="emailShowFullText"
              color="primary"
            />
          }
        />
      </div>

      <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3 mt-3">
        <div className="flex items-start gap-4 w-full">
          <img src={NewsLetter} alt="NewsLetter" />
          <div>
            <p className="font-18 font-medium text-darkblue ">
              Monthly Newsletter
            </p>
          </div>
        </div>
        <FormControlLabel
          className="black-border"
          control={
            <Switch
              checked={values.emailMonthlyNewsletter}
              onChange={(e) => {
                handleChange(e);
                setFieldValue(
                  "emailMonthlyNewsletter",
                  !values.emailMonthlyNewsletter
                );
                handleSubmit({
                  ...values,
                  emailMonthlyNewsletter: !values.emailMonthlyNewsletter,
                });
              }}
              name="emailMonthlyNewsletter"
              color="primary"
            />
          }
        />
      </div>
      <div className="gray-border border-radius-10 p-3 mt-3">
        <p className="font-20 font-medium text-darkblue mb-3">
          Subscriptions and Following
        </p>
        <div className="site-notification items-center px-2 py-1 border-radius-10 gap-3 mb-4">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.includes("new-post-summary")}
                onChange={(e) => handleCheckboxChange(e)}
                value={"new-post-summary"}
                color="primary"
              />
            }
            label="New Posts Summary"
            style={{
              color: checked?.includes("new-post-summary")
                ? "#2799F6"
                : "#65717B",
              padding: "0 9px !important",
            }}
          />
        </div>

        {checked?.includes("new-post-summary") && (
          <div className="pl-10 mb-3">
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="emailNewPostSummary"
                value={
                  values.emailNewPostSummary ? values.emailNewPostSummary : ""
                }
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit({
                    ...values,
                    emailNewPostSummary: e.target.value,
                  });
                }}
              >
                <MenuItem value={"none"}>None</MenuItem>
                <MenuItem value={"everyHour"}>Every Hours</MenuItem>
                <MenuItem value={"every3Hour"}>Every 3 Hours</MenuItem>
                <MenuItem value={"every6Hour"}>Every 6 Hours</MenuItem>
                <MenuItem value={"every12Hour"}>Every 12 Hours</MenuItem>
                <MenuItem value={"every24Hour"}>Every 24 Hours</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="site-notification px-2 py-1 border-radius-10 gap-3 mb-4">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.includes("new-stream")}
                onChange={(e) => handleCheckboxChange(e)}
                value={"new-stream"}
                color="primary"
              />
            }
            label="New Stream"
            style={{
              color: checked?.includes("new-stream") ? "#2799F6" : "#65717B",
            }}
          />
        </div>
        {checked.includes("new-stream") && (
          <div className="pl-10 mb-3">
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="emailNewStream"
                value={values.emailNewStream ? values.emailNewStream : ""}
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit({ ...values, emailNewStream: e.target.value });
                }}
              >
                <MenuItem value={"none"}>None</MenuItem>
                <MenuItem value={"everyHour"}>Every Hours</MenuItem>
                <MenuItem value={"every3Hour"}>Every 3 Hours</MenuItem>
                <MenuItem value={"every6Hour"}>Every 6 Hours</MenuItem>
                <MenuItem value={"every12Hour"}>Every 12 Hours</MenuItem>
                <MenuItem value={"every24Hour"}>Every 24 Hours</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="site-notification px-2 py-1 border-radius-10 gap-3 mb-4">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.includes("upcoming-stream-reminders")}
                onChange={(e) => handleCheckboxChange(e)}
                readOnly={true}
                value={"upcoming-stream-reminders"}
                color="primary"
              />
            }
            label="Upcoming Stream Reminders"
            style={{
              color: checked?.includes("upcoming-stream-reminders")
                ? "#2799F6"
                : "#65717B",
            }}
          />
        </div>
        {checked.includes("upcoming-stream-reminders") && (
          <div className="pl-10 mb-3">
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="emailUpcomingStreamReminders"
                value={
                  values.emailUpcomingStreamReminders
                    ? values.emailUpcomingStreamReminders
                    : ""
                }
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit({
                    ...values,
                    emailUpcomingStreamReminders: e.target.value,
                  });
                }}
              >
                <MenuItem value={"none"}>None</MenuItem>
                <MenuItem value={"everyHour"}>Every Hours</MenuItem>
                <MenuItem value={"every3Hour"}>Every 3 Hours</MenuItem>
                <MenuItem value={"every6Hour"}>Every 6 Hours</MenuItem>
                <MenuItem value={"every12Hour"}>Every 12 Hours</MenuItem>
                <MenuItem value={"every24Hour"}>Every 24 Hours</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </div>
      <div className="gray-border border-radius-10 p-3 mt-3">
        <p className="font-20 font-medium text-darkblue mb-3">New Messages</p>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3 mb-4">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.includes("new-private-message")}
                onChange={(e) => handleCheckboxChange(e)}
                value={"new-private-message"}
                color="primary"
              />
            }
            label="New Private Message Summary"
            style={{
              color: checked?.includes("new-private-message")
                ? "#2799F6"
                : "#65717B",
            }}
          />
        </div>
        {checked.includes("new-private-message") && (
          <div className="pl-10 mb-3">
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="emailNewPrivateMessage"
                value={
                  values.emailNewPrivateMessage
                    ? values.emailNewPrivateMessage
                    : ""
                }
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit({
                    ...values,
                    emailNewPrivateMessage: e.target.value,
                  });
                }}
              >
                <MenuItem value={"none"}>None</MenuItem>
                <MenuItem value={"everyHour"}>Every Hours</MenuItem>
                <MenuItem value={"every3Hour"}>Every 3 Hours</MenuItem>
                <MenuItem value={"every6Hour"}>Every 6 Hours</MenuItem>
                <MenuItem value={"every12Hour"}>Every 12 Hours</MenuItem>
                <MenuItem value={"every24Hour"}>Every 24 Hours</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </div>
      <div className="gray-border border-radius-10 p-3 mt-3">
        <p className="font-20 font-medium text-darkblue mb-3">
          Related to You and Your Posts
        </p>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3 mb-2">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.includes("new-subscriber")}
                onChange={(e) => handleCheckboxChange(e)}
                value={"new-subscriber"}
                color="primary"
              />
            }
            style={{
              color: checked?.includes("new-subscriber")
                ? "#2799F6"
                : "#65717B",
            }}
            label="New Subscriber"
          />
        </div>
        {checked.includes("new-subscriber") && (
          <div className="pl-10 mb-3">
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="emailNewSubscriber"
                value={
                  values.emailNewSubscriber ? values.emailNewSubscriber : ""
                }
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit({
                    ...values,
                    emailNewSubscriber: e.target.value,
                  });
                }}
              >
                <MenuItem value={"none"}>None</MenuItem>
                <MenuItem value={"everyHour"}>Every Hours</MenuItem>
                <MenuItem value={"every3Hour"}>Every 3 Hours</MenuItem>
                <MenuItem value={"every6Hour"}>Every 6 Hours</MenuItem>
                <MenuItem value={"every12Hour"}>Every 12 Hours</MenuItem>
                <MenuItem value={"every24Hour"}>Every 24 Hours</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="site-notification px-2 py-1 border-radius-10 gap-3 mb-2">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked?.includes("new-subscription-from-trial")}
                onChange={(e) => handleCheckboxChange(e)}
                value={"new-subscription-from-trial"}
                color="primary"
              />
            }
            style={{
              color: checked?.includes("new-subscription-from-trial")
                ? "#2799F6"
                : "#65717B",
            }}
            label="New Subscription from Trial"
          />
        </div>
        {checked.includes("new-subscription-from-trial") && (
          <div className="pl-10 mb-3">
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="emailNewSubscriptionFromTrial"
                value={
                  values.emailNewSubscriptionFromTrial
                    ? values.emailNewSubscriptionFromTrial
                    : ""
                }
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit({
                    ...values,
                    emailNewSubscriptionFromTrial: e.target.value,
                  });
                }}
              >
                <MenuItem value={"none"}>None</MenuItem>
                <MenuItem value={"everyHour"}>Every Hours</MenuItem>
                <MenuItem value={"every3Hour"}>Every 3 Hours</MenuItem>
                <MenuItem value={"every6Hour"}>Every 6 Hours</MenuItem>
                <MenuItem value={"every12Hour"}>Every 12 Hours</MenuItem>
                <MenuItem value={"every24Hour"}>Every 24 Hours</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3 mt-4">
          <div className="flex items-start gap-4 w-full">
            <img src={ReceiveNotification} alt="Receive Notification" />
            <div>
              <p className="font-18 font-medium text-darkblue ">
                Receive Less Notifications
              </p>
              <p>Receive email notifications for direct messages only.</p>
            </div>
          </div>
          <FormControlLabel
            className="black-border"
            control={
              <Switch
                checked={values.emailReceiveLessNotification}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "emailReceiveLessNotification",
                    !values.emailReceiveLessNotification
                  );
                  handleSubmit({
                    ...values,
                    emailReceiveLessNotification:
                      !values.emailReceiveLessNotification,
                  });
                }}
                color="primary"
              />
            }
          />
        </div>
        <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3 mt-4">
          <div className="flex items-start gap-4 w-full">
            <img src={ImpSubscription} alt="Imp Subscription" />
            <div>
              <p className="font-18 font-medium text-darkblue ">
                Important Subscription Notifications
              </p>
              <p>
                Notifications about upcoming subscription renewals and
                expirations
              </p>
            </div>
          </div>
          <FormControlLabel
            className="black-border"
            control={
              <Switch
                checked={values.emailImportantSubscriptionNotification}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "emailImportantSubscriptionNotification",
                    !values.emailImportantSubscriptionNotification
                  );
                  handleSubmit({
                    ...values,
                    emailImportantSubscriptionNotification:
                      !values.emailImportantSubscriptionNotification,
                  });
                }}
                name="important-subscription-notification"
                color="primary"
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default ModalEmailNotifications;
