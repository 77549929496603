function loginUser({ user }) {
  localStorage.setItem("user-details-admin", JSON.stringify(user));
}

function removeFromLocalStorage(name) {
  localStorage.removeItem(`${name}`);
}

function loginWeb(userData) {
  localStorage.setItem("user-details", JSON.stringify(userData));
}

function getFromLocalStorage(name) {
  try {
    const item = localStorage.getItem(name);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return null;
  }
}

async function replaceToken(encryptedToken) {
  const userDetails = getFromLocalStorage("user-details");
  if (userDetails) {
    userDetails.token = encryptedToken;
    localStorage.setItem("user-details", JSON.stringify(userDetails));
  }
}

const LocalStorageService = {
  loginUser,
  loginWeb,
  removeFromLocalStorage,
  replaceToken,
  getFromLocalStorage,
};

export default LocalStorageService;
