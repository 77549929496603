import { createAsyncThunk } from "@reduxjs/toolkit";
import LocalStorageService from "../../../../utils/localstorage-services";

export const checkStoredAuthAction = createAsyncThunk(
  "auth/checkStoredAuth",
  async (_, { rejectWithValue }) => {
    try {
      const storedUser = LocalStorageService.getFromLocalStorage("user-details");
      
      if (!storedUser?.token) {
        return rejectWithValue({ message: "No valid stored auth found" });
      }

      return {
        response: storedUser,
        message: "Auth restored from storage"
      };
    } catch (error) {
      console.error('Error checking stored auth:', error);
      return rejectWithValue({ message: error.message });
    }
  }
);
