import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axiosClient from "../utils/api";
import { openToast } from "../store/slices/common/toast";
import { usePermission } from "@custom-react-hooks/use-permission";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

const isSupported = () => {
  if (!("serviceWorker" in navigator)) {
    return false;
  }
  if (!("Notification" in window)) {
    return false;
  }
  return true;
};

const messaging = isSupported() ? getMessaging(app) : null;

export const requestNotificationPermission = async () => {
  if (!isSupported()) {
    console.log(
      "This browser does not support notifications or service workers"
    );
    return null;
  }

  try {
    let token = undefined;
    try {
      token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        forceRefresh: true,
      });
    } catch (error) {
      console.error("Failed to get FCM token:", error);
      throw error;
    }

    if (token) {
      try {
        const response = await axiosClient.post("/notifications/register-token", { fcmToken: token });
        return token;
      } catch (error) {
        console.error("Failed to register token with backend:", error);
        throw error;
      }
    } else {
      console.log('Token not present.')
    }

    const { state: permission } = usePermission("notifications");

    if (permission !== "granted") {
      return null;
    }

    return null;
  } catch (error) {
    console.error("Notification permission error:", error);
    throw error;
  }
};

export const onMessageListener = (dispatch) => {
  if (!messaging) {
    return Promise.reject(
      new Error("Messaging is not supported in this browser")
    );
  }

  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      if (payload.data && payload.data.type === "toast") {
        dispatch(
          openToast({
            message: payload.data.title + " : " + payload.data.body,
            type: "info",
            duration: 3000,
          })
        );
      }
      resolve(payload);
    });
  });
};
