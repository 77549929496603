import React from "react";
import MediaItem from "../MediaItem";
import { Box, Grid } from "@mui/material";

const MediaSearchResults = ({ items = [], onSelectItem, isMultiDrag }) => {
  return (
    <Grid container>
      {items.map((item) => (
        <Grid item xs={12} sm={6} md={4} key={item.id}>
          <Box sx={{ p: 2 }}>
            <MediaItem
              id={item.id}
              type={item.type}
              thumbnail={item.thumbnail}
              duration={item.duration}
              date={item.date}
              selected={item.selected}
              onSelect={() => onSelectItem(item.id)}
              isMultiDrag={isMultiDrag}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default MediaSearchResults;
