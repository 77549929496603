import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../../../../store/middlewares/auth/session/auth";
import {
  deleteSessionsAction,
  getSessions,
} from "../../../../../../store/middlewares/web/profile/myProfile/me";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { TrashIcon } from "../../../../../../assets/SVG/TrashIcon";
import { getColor } from "../../../../../../utils/colors";

const ModalLoginSessions = () => {
  const { sessions, sessionsLoading } = useSelector((state) => state?.me);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSessions());
  }, [dispatch]);

  const onClose = () => {
    dispatch(closeModal());
  };
  return (
    <>
      <Box className="flex flex-col gap-x-2.5">
        {sessionsLoading && <CircularProgress />}
        {!sessionsLoading &&
          (sessions?.length ? (
            sessions?.map((session, index) => {
              let { browser, version, os, ipAddress, isActive } = session;
              return (
                <FormControl
                  variant="outlined"
                  key={`${browser} ${version}, ${os} - ${ipAddress} - ${index}`}
                  className={`w-full mt-3 ${isActive ? "border-success" : ""} relative`}
                >
                  <OutlinedInput
                    defaultValue={`${browser} ${version}, ${os} - ${ipAddress}`}
                    id={`${browser} ${version}, ${os} - ${ipAddress}`}
                    readOnly={true}
                    endAdornment={
                      <IconButton
                        onClick={() => dispatch(deleteSessionsAction({ data: session.id }))}
                        edge="end"
                      >
                        <TrashIcon size={20} color={getColor("red")} type="filled" />
                      </IconButton>
                    }
                  />
                </FormControl>
              );
            })
          ) : (
            <Box className="flex justify-between items-center">
              There are no other active sessions.
            </Box>
          ))}
      </Box>

      <Box className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="m-auto px-12"
          disabled={sessionsLoading || !sessions?.length}
          onClick={() => {
            const tempString = sessions
              .map((session) => {
                return session.id;
              })
              .join(",");
            dispatch(deleteSessionsAction({ data: tempString }));
          }}
        >
          Close Other Sessions
        </Button>
      </Box>
    </>
  );
};

export default ModalLoginSessions;
