import React, { useEffect, useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  SwipeableDrawer,
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import MessageIcon from "@mui/icons-material/Message";
import ExploreIcon from "@mui/icons-material/Explore";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CloseIcon from "@mui/icons-material/Close";
import sideBarItems from "../utils/sideBarItems";
import creatorSideBarItems from "../utils/creatorSideBarItems";
import MyProfileIcon from "../assets/SVG/SideBar/MyProfileIcon";
import UserListsIcon from "../assets/SVG/SideBar/UserListsIcon";
import BookMarksIcon from "../assets/SVG/SideBar/BookMarksIcon";
import YourCardsIcon from "../assets/SVG/SideBar/YourCardsIcon";
import BankIcon from "../assets/SVG/SideBar/BankIcon";
import HelpIcon from "../assets/SVG/SideBar/HelpIcon";
import { DarkMode } from "@mui/icons-material";
import { SettingsIcon } from "lucide-react";
import EnglishIcon from "../assets/SVG/SideBar/EnglishIcon";
import { useDispatch, useSelector } from "react-redux";
import { toggleDarkModeAction } from "../store/middlewares/web/profile/myProfile/profiledisplay";
import { openModal } from "../store/slices/common/modal";
import { ModalLogout } from "../newComponents/molecules";
import LogoutIcon from "../assets/images/logout.svg";

const BottomMenuNavigation = ({ userType }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.me);

  useEffect(() => {
    setDrawerOpen(false);
    setShowMore(false);
  }, [location.pathname]);

  // Messages route check moved here after all hooks
  if (location.pathname.startsWith('/messages')) {
    return null;
  }

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
    if (!open) {
      setShowMore(false);
    }
  };

  // Get current active tab based on pathname
  const getCurrentTab = () => {
    if (location.pathname.startsWith('/home')) return 0;
    if (location.pathname.startsWith('/messages')) return 1;
    if (location.pathname.startsWith('/explore')) return 2;
    if (location.pathname.startsWith('/vault') && userType === 'creator') return 3;
    return false; // No tab selected
  };

  /**
   * Handles navigation logic based on the item object.
   *
   * @param {Object} item - The navigation item object.
   * @param {string} item.slug - The slug used for navigation (e.g., "home", "messages").
   * @param {boolean} item.hasPage - Indicates whether the item has a valid page to navigate to.
   *
   * Functionality:
   * - If the item's slug is "more", it sets the `showMore` state to `true` to display additional options.
   * - If the item has a valid page (`hasPage` is true), it navigates to the corresponding route using the slug.
   * - For items that don't match the above conditions, no action is performed.
   */
  const handleNavigation = (item) => {
    if (item.slug === "more") {
      setShowMore(true); // Display "more" options
    } else if (item.hasPage) {
      navigate(`/${item.slug}`); // Navigate to the item's page
    }
  };

  // Navigacione ikonice i njihovi slug-ovi
  const navButtons = [
    { icon: <HomeIcon />, label: "Home", slug: "/home" },
    { icon: <MessageIcon />, label: "Messages", slug: "/messages" },
    { icon: <ExploreIcon />, label: "Explore", slug: "/explore" },
    ...(userType === "creator"
      ? [{ icon: <FolderSpecialIcon />, label: "Vault", slug: "/vault" }]
      : []),
  ];

  const navItems =
    userType === "creator" ? creatorSideBarItems() : sideBarItems();

  const personalItems = [
    { icon: <MyProfileIcon />, label: "My Profile", slug: "my-profile" },
    { icon: <UserListsIcon />, label: "User Lists", slug: "user-lists" },
    { icon: <BookMarksIcon />, label: "Bookmarks", slug: "bookmarks" },
  ];

  const walletItems = [
    {
      icon: <YourCardsIcon />,
      label: "Your Cards",
      helperText: "To Subscribe",
    },
    { icon: <BankIcon />, label: "Bank", helperText: "To Earn" },
  ];

  const toolsItems = [
    { icon: <HelpIcon />, label: "Help And Support", slug: "help-and-support" },
    { icon: <DarkMode />, label: "Dark Mode", slug: "dark-mode" },
    { icon: <SettingsIcon />, label: "Settings", slug: "settings" },
    { icon: <EnglishIcon />, label: "English", slug: "english" },
  ];

  return (
    <>
      {/* Bottom Navigation */}
      <BottomNavigation
        value={getCurrentTab()}
        onChange={(event, newValue) => {
          if (newValue !== 3) { // 3 is the NAV button
            navigate(navButtons[newValue].slug);
          }
        }}
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
          zIndex: 1300,
        }}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          label="Messages"
          icon={<MessageIcon />}
        />
        <BottomNavigationAction
          label="Explore"
          icon={<ExploreIcon />}
        />
        {/* NAV Ikonica */}
        <BottomNavigationAction
          label="NAV"
          icon={<TravelExploreIcon />}
          onClick={toggleDrawer(true)}
        />
      </BottomNavigation>

      {/* Drawer koji se otvara */}
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          sx: {
            widtd: "50%",
            padding: 2,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            overflow: "auto",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 1,
            }}
          >
            <Typography
              display="inline-block"
              sx={{ alignSelf: "center", m: 0 }}
              variant="h6"
              gutterBottom
            >
              {!showMore ? "Navigation" : "More..."}
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          {!showMore ? (
            <Box>
              <List>
                <List>
                  {navItems.map((item, index) => (
                    <ListItemButton
                      key={index}
                      onClick={() => handleNavigation(item)}
                      className={location.pathname.includes(item?.slug) ? "active" : ""}
                    >
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        secondary={item.count ? `${item.count} items` : null}
                      />
                    </ListItemButton>
                  ))}
                </List>
                {userType === "creator" && (
                  <Box sx={{ mt: "auto", padding: 2 }}>
                    <Button
                      className="light-blue-btn w-full"
                      sx={{ mt: 2, maxHeight: "47px !important" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        navigate(`/new-post`);
                        toggleDrawer(false);
                      }}
                    >
                      New Post
                    </Button>
                  </Box>
                )}
              </List>
            </Box>
          ) : (
            <Box>
              {/* Sadržaj "More" */}
              <List>
                <Typography variant="subtitle1">Personal</Typography>
                {personalItems.map((item, index) => (
                  <ListItemButton
                    key={index}
                    onClick={() => navigate(`/${item.slug}`)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                ))}
                <Typography variant="subtitle1">Wallet</Typography>
                {walletItems.map((item, index) => (
                  <ListItemButton key={index}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      secondary={item.helperText}
                    />
                  </ListItemButton>
                ))}
                <Typography variant="subtitle1">Tools</Typography>
                {toolsItems.map((item, index) => (
                  <ListItemButton
                    key={index}
                    onClick={() => {
                      if (item?.slug === "dark-mode") {
                        dispatch(
                          toggleDarkModeAction({ mode: me?.isDarkMode })
                        );
                      }
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                ))}
              </List>
              <Button
                fullWidth
                variant="contained"
                className="red-btn w-full"
                sx={{ mt: 2, minHeight: "47px !important" }}
                onClick={() => {
                  setDrawerOpen(false);
                  dispatch(
                    openModal({
                      title: "Logout",
                      content: <ModalLogout />,
                      hasCloseBtn: true,
                    })
                  );
                }}
              >
                Log Out <img src={LogoutIcon} alt="LogoutIcon" />
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ pt: 2, pb: 2 }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ minHeight: "47px !important" }}
            onClick={toggleDrawer(false)}
          >
            Close
          </Button>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default BottomMenuNavigation;
