import React, { useState, useEffect } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';

const FolderSelector = ({
  folders = [],
  isLoading = false,
  onFolderSelect,
  onCreateFolder,
  selectedFolderId = '',
}) => {
  const [value, setValue] = useState(selectedFolderId);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setValue(selectedFolderId);
  }, [selectedFolderId]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'create_new') {
      setIsCreateDialogOpen(true);
    } else {
      setValue(selectedValue);
      onFolderSelect(selectedValue);
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      setError('Folder name cannot be empty');
      return;
    }

    try {
      const newFolder = await onCreateFolder(newFolderName);
      setNewFolderName('');
      setIsCreateDialogOpen(false);
      setError('');
      
      if (newFolder?.id) {
        setValue(newFolder.id);
        onFolderSelect(newFolder.id);
      }
    } catch (err) {
      setError(err.message || 'Failed to create folder');
    }
  };

  const handleClose = () => {
    setIsCreateDialogOpen(false);
    setNewFolderName('');
    setError('');
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <>
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select folder' }}
        >
          <MenuItem value="" disabled>
            Select a folder
          </MenuItem>
          {folders.map((folder) => (
            <MenuItem key={folder.id} value={folder.id}>
              {folder.folderName}
            </MenuItem>
          ))}
          <MenuItem value="create_new">
            <AddIcon sx={{ mr: 1 }} /> Create New Folder
          </MenuItem>
        </Select>
      </FormControl>

      <Dialog open={isCreateDialogOpen} onClose={handleClose}>
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreateFolder} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

FolderSelector.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      folderName: PropTypes.string.isRequired,
    })
  ),
  isLoading: PropTypes.bool,
  onFolderSelect: PropTypes.func.isRequired,
  onCreateFolder: PropTypes.func.isRequired,
  selectedFolderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FolderSelector;
