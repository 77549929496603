import {
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import SaveForLaterImg from "./../../../../../assets/images/saveforlater.png";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../../store/slices/common/modal";
import { getUserListFolderAction } from "../../../../../store/middlewares/web/folders/folders";
import { upsertUserToUserListAction } from "../../../../../store/middlewares/admin/users/users";
import { addUserListFolderAction } from "../../../../../store/middlewares/web/folders/folders";
import FolderSelector from "../../common/FolderSelector";

function ModelSaveToList({ userId, refresh }) {
  const dispatch = useDispatch();
  const [selectedFolder, setSelectedFolder] = useState("");

  const { folders, foldersLoading } = useSelector((state) => state.folders);

  useEffect(() => {
    dispatch(getUserListFolderAction());
  }, [dispatch]);

  const handleFolderSelect = (folderId) => {
    setSelectedFolder(folderId);
  };

  const handleCreateFolder = async (folderName) => {
    const result = await dispatch(addUserListFolderAction({ folderName })).unwrap();
    if (result?.response?.folder) {
      await dispatch(getUserListFolderAction());
      return result.response.folder;
    }
    throw new Error('Failed to create folder');
  };

  const handleAddToUserList = () => {
    if (!selectedFolder) return;
    dispatch(upsertUserToUserListAction({ userId, folderId: selectedFolder, refresh }));
    dispatch(closeModal());
  };

  if (foldersLoading) {
    return (
      <Box className="bg-white rounded-2xl p-4 flex items-start justify-center">
        <CircularProgress />
      </Box>
    );
  }

  if (folders?.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center light-gray700-bg p-4 rounded-2xl">
        <img src={SaveForLaterImg} alt="SaveForLaterImg" width={198} />
        <p className="font-20">Nothing found</p>
        <FolderSelector
          folders={[]}
          onFolderSelect={handleFolderSelect}
          onCreateFolder={handleCreateFolder}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <FolderSelector
        folders={folders}
        selectedFolderId={selectedFolder}
        onFolderSelect={handleFolderSelect}
        onCreateFolder={handleCreateFolder}
      />
      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto px-12"
          onClick={handleAddToUserList}
          disabled={!selectedFolder}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default ModelSaveToList;
