import axiosClient from "../../../../utils/api";

export const verifyOTP = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/auth/verifyOtp",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (err) {
    console.log("Error at OTP Service", err);
    throw err;
  }
};
