import axiosClient from '../../../../utils/api';

export const getMessages = async (filter = 'all', cursor = null) => {
  try {
    const response = await axiosClient.request({
      url: '/chat/recent-conversations',
      method: 'get',
      params: {
        filter,
        ...(cursor && { cursor })
      }
    });
    return {
      items: response.data?.response?.items || [],
      nextCursor: response.data?.response?.nextCursor
    };
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
};

export const getMessageThread = async (chatId, cursor = null) => {
  try {

    const response = await axiosClient.request({
      url: `/chat/${chatId}/messages`,
      method: 'get',
      params: cursor ? { cursor } : undefined
    });
    
    // Mark messages as viewed when opening the thread
    try {
      await axiosClient.request({
        url: `/chat/${chatId}/mark-viewed`,
        method: 'post'
      });
    } catch (markError) {
      console.error('Error marking messages as viewed:', markError);
    }

    return {
      id: chatId,
      messages: response.data?.response?.data || [],
      nextCursor: response.data?.response?.nextCursor
    };
  } catch (error) {
    console.error('Error fetching message thread:', error);
    throw error;
  }
};

export const sendMessage = async (messageData) => {
  try {
    console.log('Making API call to send message:', messageData);
    const response = await axiosClient.request({
      url: '/chat/message',
      method: 'post',
      data: {...messageData} 
    });
    
    console.log('Message sent successfully, response:', response.data);
    return response.data?.response;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const pinChat = async (chatId) => {
  try {
    const response = await axiosClient.request({
      url: `/chat/${chatId}/pin`,
      method: 'post'
    });
    return response.data?.response;
  } catch (error) {
    console.error('Error pinning chat:', error);
    throw error;
  }
};

export const unpinChat = async (chatId) => {
  try {
    const response = await axiosClient.request({
      url: `/chat/${chatId}/unpin`,
      method: 'post'
    });
    return response.data?.response;
  } catch (error) {
    console.error('Error unpinning chat:', error);
    throw error;
  }
};

export const reportMessage = async (messageId, reportData) => {
  try {
    const response = await axiosClient.request({
      url: `/chat/message/${messageId}/report`,
      method: 'post',
      data: reportData
    });
    return response.data?.response;
  } catch (error) {
    console.error('Error reporting message:', error);
    throw error;
  }
};

export const deleteMessage = async (messageId) => {
  try {
    const response = await axiosClient.request({
      url: `/chat/message/${messageId}`,
      method: 'delete'
    });
    return response.data?.response;
  } catch (error) {
    console.error('Error deleting message:', error);
    throw error;
  }
}; 