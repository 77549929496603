import axiosClient from "../../../../utils/api";
// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/user";

export const getActiveCreator = async () => {
  const URL = `${URI}/getCreators`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Active Creator:", error);
    throw error;
  }
};

export const getPendingCreator = async () => {
  const URL = `admin/creatorRequest/getAll`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Pending Creator:", error);
    throw error;
  }
};

export const updateCreatorRequestStatus = async (payload) => {
  const URL = "admin/creatorRequest/update";
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Pending Creator:", error);
    throw error;
  }
};

export const getBannedCreator = async () => {
  const URL = `${URI}/getDeletedCreators`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Getting Banned User:", error);
    throw error;
  }
};
