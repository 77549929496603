import { createAsyncThunk } from "@reduxjs/toolkit";
import { getVerificationRequests } from "../../../services/admin/verification/ondatoVerification.services";

export const getVerificationRequestsAction = createAsyncThunk(
  "verification/getRequests",
  async (params, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getVerificationRequests(params);
      if (response.status === 1) {
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
