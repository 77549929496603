import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../../../assets/images/logo.svg";
import { closeModal } from "../../../../../store/slices/common/modal";
import { resendIdentityVerificationEmailAction } from "../../../../../store/middlewares/auth/session/auth";
import { formatDuration } from "../../../../../utils/formatDuration";
import { EMAIL_COOLDOWN } from "../../../../../constants";

const ModalVerifyEmail = ({ email, name }) => {
  const { me } = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(0);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleResend = () => {
    dispatch(resendIdentityVerificationEmailAction({email, name}));
    setCountdown(EMAIL_COOLDOWN);
  };

  useEffect(() => {
    const lastSent = me.lastIdentityVerificationEmailSent;
      if (lastSent) {
        const lastSentDate = new Date(lastSent);
        const now = new Date();
        const diff = now - lastSentDate;
        const remaining = EMAIL_COOLDOWN - diff;
  
        if (remaining > 0) {
          setCountdown(remaining);
        }
      }
  }, [me]);
  

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown((prevCountdown) => prevCountdown - 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);

  useEffect(() => {
    if(me.lastIdentityVerificationEmailSent){
      const lastSent = me.lastIdentityVerificationEmailSent;
      const lastSentDate = new Date(lastSent);
      const now = new Date();
      const diff = now - lastSentDate;
      const remaining = EMAIL_COOLDOWN - diff;
      setCountdown(remaining > 0 ? remaining : 0);
    }
  }, [me.lastIdentityVerificationEmailSent])


  return (
    <>
      <div className="flex justify-center items-center pb-3 pt-5">
        <div className="flex flex-col">
          <div className="flex justify-center items-center pb-7">
            <img src={Logo} alt={"Logout"} className="w-60 " />
          </div>
          <h3 className="text-lg text-center text-[#1E405C] font-semibold pb-3">
            Email not verified
          </h3>
          <span className="pb-3">
            We’ve sent you a verification email upon registration. Please check
            your inbox and follow the instructions to verify your email.
          </span>
          <span className="text-sm">
            If you can’t find the email, click the button below to resend it and
            follow the instructions from the email.
          </span>
          {countdown > 0 && (
            <span className="text-sm text-red-500 mt-4">
              Please wait {formatDuration(Math.floor(countdown / 1000))} before requesting a new
              verification email.
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          className="m-auto px-12"
          onClick={handleResend}
          disabled={countdown > 0}
        >
          {countdown > 0 ? formatDuration(Math.floor(countdown / 1000)) : "Resend"}
        </Button>
      </div>
    </>
  );
};

export default ModalVerifyEmail;