import { createSlice } from '@reduxjs/toolkit';
import { getMessagesAction, getMessageThreadAction, sendMessageAction } from '../../middlewares/web/messages/messages';

const initialState = {
  filter: 'all', // 'all' | 'unread'
  sortOrder: 'desc', // 'asc' | 'desc'
  searchQuery: '',
  selectedChat: null,
  messages: [],
  nextCursor: null,
  currentThread: null,
  threadNextCursor: null,
  loading: false,
  error: null,
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessageFilter: (state, action) => {
      state.filter = action.payload;
    },
    setMessageSort: (state, action) => {
      state.sortOrder = action.payload;
    },
    setMessageSearch: (state, action) => {
      state.searchQuery = action.payload;
    },
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    clearSelectedChat: (state) => {
      state.selectedChat = null;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    addNewChat: (state, action) => {
      const newChat = action.payload;
      // Remove existing chat if it exists (by id or if it's a new chat for same user)
      state.messages = state.messages.filter(chat => 
        chat.id !== newChat.id && 
        !(chat.id === 'new' && chat.user?.id === newChat.user?.id)
      );
      // Add new chat at the beginning
      state.messages.unshift(newChat);
    },
    setCurrentThread: (state, action) => {
      state.currentThread = action.payload;
    },
    clearCurrentThread: (state) => {
      state.currentThread = null;
      state.threadNextCursor = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Messages
      .addCase(getMessagesAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMessagesAction.fulfilled, (state, action) => {
        state.loading = false;
        // Transform the conversations into our chat format
        const items = action.payload?.items || [];
        state.messages = items.map(conv => ({
          id: conv.chatId,
          chatId: conv.chatId,
          user: conv.user,
          name: conv.user?.name,
          profileImage: conv.user?.profileImage,
          isOnline: conv.user?.isOnline,
          lastMessage: conv.lastMessage?.text,
          totalUnreadMessagesNumber: conv.totalUnreadMessagesNumber || 0,
          isPinned: conv.isPinned,
          pinnedOrder: conv.pinnedOrder || 0,
          messages: conv.messages || []
        }));
        state.nextCursor = action.payload?.nextCursor;
      })
      .addCase(getMessagesAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Get Message Thread
      .addCase(getMessageThreadAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMessageThreadAction.fulfilled, (state, action) => {
        state.loading = false;
        
        const messages = action.payload.messages.map(({ id, senderId, text, createdAt, updatedAt, viewedAt, media = [], offer = null, tip = null }) => 
          ({ id, senderId, text, createdAt, updatedAt, viewedAt, media, offer, tip })
        );

        if (state.currentThread?.id === action.payload.id) {
          const uniqueMessages = [...state.currentThread.messages, ...messages]
            .reduce((acc, msg) => ({ ...acc, [msg.id]: msg }), {});
          state.currentThread.messages = Object.values(uniqueMessages)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        } else {
          state.currentThread = {
            id: action.payload.id,
            messages: messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          };
        }

        if (!state.selectedChat && messages.length) {
          state.selectedChat = { user: { id: messages[0].senderId } };
        }

        state.threadNextCursor = action.payload?.nextCursor || null;
      })
      .addCase(getMessageThreadAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Send Message
      .addCase(sendMessageAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendMessageAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendMessageAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setMessageFilter,
  setMessageSort,
  setMessageSearch,
  setSelectedChat,
  clearSelectedChat,
  setMessages,
  addNewChat,
  setCurrentThread,
  clearCurrentThread,
  setLoading,
  setError,
} = messagesSlice.actions;

export default messagesSlice.reducer; 