import React from "react";

const AllIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.492 2.16663H6.50866C3.47533 2.16663 1.66699 3.97496 1.66699 7.00829V13.9833C1.66699 17.025 3.47533 18.8333 6.50866 18.8333H13.4837C16.517 18.8333 18.3253 17.025 18.3253 13.9916V7.00829C18.3337 3.97496 16.5253 2.16663 13.492 2.16663ZM15.417 14.1666C15.417 15.4166 14.917 15.9166 13.667 15.9166H10.5003C9.25033 15.9166 8.75033 15.4166 8.75033 14.1666V12.6666C8.75033 11.4166 9.25033 10.9166 10.5003 10.9166H13.667C14.917 10.9166 15.417 11.4166 15.417 12.6666V14.1666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AllIcon