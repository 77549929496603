import { Button } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../../../store/slices/common/modal';
import { deleteStory } from '../../../../../store/services/web/post/posts.services';

const ModalDeleteStoryMedia = ({ postId, mediaId, onSuccess, onCancel }) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal());
        onCancel?.();
    }

    const handleDelete = async () => {
        try {
            await deleteStory({ postId, mediaId });
            onSuccess();
            dispatch(closeModal());
        } catch (error) {
            console.error("Error deleting story media:", error);
            handleClose();
        }
    }

    return (
        <>
            <div className='flex justify-center items-center pb-3 pt-5'>
                <div>
                    <h3 className='text-lg text-center text-[#1E405C] font-semibold'>
                        Are you sure you want to delete this media from your story?
                    </h3>
                </div>
            </div>
            <div className='flex justify-end mt-6 gap-3'>
                <Button variant="contained" className='m-auto white-btn' onClick={handleClose}>Cancel</Button>
                <Button variant="contained" className='m-auto px-12 delete-button' onClick={handleDelete}>Delete</Button>
            </div>
        </>
    )
}

export default ModalDeleteStoryMedia
