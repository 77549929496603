import axiosClient from "../../../../utils/api";

export const getCms = async (payload) => {
  try {
    const resopnse = await axiosClient.request({
      url: "/cms/getCms",
      method: "post",
      data: payload,
    });
    return resopnse.data;
  } catch (error) {
    console.log("Error at getCms Service", error);
    throw error;
  }
};
