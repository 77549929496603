import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function TwoFactorNavigator() {
  const navigate = useNavigate();
  const { requires2FA, tempToken } = useSelector((state) => state.webauth);

  useEffect(() => {
    
    // Check if we need to navigate to 2FA
    if (requires2FA && tempToken) {
      console.log('Navigating to 2FA verification');
      navigate('/verify-2fa', { replace: true });
    }
  }, [requires2FA, tempToken, navigate]);

  return null;
}
