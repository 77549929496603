import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BanUser,
  DeleteUser,
  GetUser,
  NotificationUser,
} from "./../../../services/admin/users/user.js";
import { openToast } from "../../../slices/common/toast/index.js";

export const NotificationUserAction = createAsyncThunk(
  "user/getNotification",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await NotificationUser({ id: payload });
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetUserAction = createAsyncThunk(
  "user/getUser",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await GetUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const banUserAction = createAsyncThunk(
  "users/banUser",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await BanUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const deleteUserAction = createAsyncThunk(
  "users/deleteUser",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await DeleteUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.location = "/admin/dashboard";
        return fulfillWithValue({ ...response, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

// export const deleteUser = createAsyncThunk("users/deleteUser", async (id) => {
//   let user = localStorage.getItem("user-details-admin");
//   try {
//     await axiosClient.post(
//       `${process.env.REACT_APP_API_URL}/admin/user/deleteUser`,
//       { id: id },
//       {
//         headers: {
//           Authorization: `Bearer ${JSON.parse(user).token}`,
//         },
//       }
//     );
//     return { id: id };
//   } catch (err) {
//     return err.message;
//   }
// });
