import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationsPreferencesAction,
  updateNotificationsAction,
} from "../../../../../../store/middlewares/web/profile/myProfile/notifications";

const ModalToastNotifications = () => {
  const schema = Yup.object({});

  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.notifications);

  const getNotification = useCallback(() => {
    dispatch(getNotificationsPreferencesAction());
  }, [dispatch]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  const handleSubmit = (values) => {
    // handle Submit
    let tempUserData = { ...values };
    let reqObj = { UserData: tempUserData };
    dispatch(updateNotificationsAction(reqObj));
  };

  //initalize form object
  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      toastNewComment: items?.toastNewComment ? items?.toastNewComment : false,
      toastNewLikes: items?.toastNewLikes ? items?.toastNewLikes : false,
      toastNewSubscriber: items?.toastNewSubscriber
        ? items?.toastNewSubscriber
        : false,
      toastNewSubscriptionFromTrial: items?.toastNewSubscriptionFromTrial
        ? items?.toastNewSubscriptionFromTrial
        : false,
      toastNewStream: items?.toastNewStream ? items?.toastNewStream : false,
    },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });
  return (
    <>
      <div className="gray-border border-radius-10 p-3">
        <p className="font-20 font-medium text-darkblue mb-3">
          Related to You and Your Posts
        </p>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                name="toastNewComment"
                checked={values.toastNewComment}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("toastNewComment", !values.toastNewComment);
                  handleSubmit({
                    ...values,
                    toastNewComment: !values.toastNewComment,
                  });
                }}
                value={"new-comment"}
                color="primary"
              />
            }
            label="New Comment"
            style={{
              color: values.toastNewComment ? "#2799F6" : "#65717B",
            }}
          />
        </div>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.toastNewLikes}
                name="toastNewLikes"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("toastNewLikes", !values.toastNewLikes);
                  handleSubmit({
                    ...values,
                    toastNewLikes: !values.toastNewLikes,
                  });
                }}
                value={"new-likes"}
                color="primary"
              />
            }
            label={"New Likes"}
            style={{
              color: values.toastNewLikes ? "#2799F6" : "#65717B",
            }}
          />
        </div>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.toastNewSubscriber}
                name="toastNewSubscriber"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "toastNewSubscriber",
                    !values.toastNewSubscriber
                  );
                  handleSubmit({
                    ...values,
                    toastNewSubscriber: !values.toastNewSubscriber,
                  });
                }}
                value={"new-subscriber"}
                color="primary"
              />
            }
            label="New Subscriber"
            style={{
              color: values.toastNewSubscriber ? "#2799F6" : "#65717B",
            }}
          />
        </div>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.toastNewSubscriptionFromTrial}
                name="toastNewSubscriptionFromTrial"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "toastNewSubscriptionFromTrial",
                    !values.toastNewSubscriptionFromTrial
                  );
                  handleSubmit({
                    ...values,
                    toastNewSubscriptionFromTrial:
                      !values.toastNewSubscriptionFromTrial,
                  });
                }}
                value={"new-subscription-from-trial"}
                color="primary"
              />
            }
            label="New Subscription from Trial"
            style={{
              color: values.toastNewSubscriptionFromTrial
                ? "#2799F6"
                : "#65717B",
            }}
          />
        </div>
      </div>
      <div className="gray-border border-radius-10 p-3 mt-4">
        <p className="font-20 font-medium text-darkblue mb-3">
          Subscriptions and Following
        </p>
        <div className="site-notification px-2 py-1 border-radius-10 gap-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.toastNewStream}
                name="toastNewStream"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("toastNewStream", !values.toastNewStream);
                  handleSubmit({
                    ...values,
                    toastNewStream: !values.toastNewStream,
                  });
                }}
                value={"new-streams"}
                color="primary"
              />
            }
            label="New Stream"
            style={{
              color: values.toastNewStream ? "#2799F6" : "#65717B",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ModalToastNotifications;
