import React, { useRef, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const CameraPhotoCaptureDialog = ({
  isCameraOpen,
  setIsCameraOpen,
  onCapturePhoto,
}) => {
  const videoRef = useRef(null);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFrontFacing, setIsFrontFacing] = useState(true);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const enumerateDevices = async () => {
    try {
      if (isSafari) {
        await navigator.mediaDevices.getUserMedia({ video: true });
      }
      
      await new Promise(resolve => setTimeout(resolve, 500)); 
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      
      if (videoDevices.length === 0) {
        throw new Error('No cameras found');
      }

      // Check if the camera label contains keywords indicating front/back
      const detectFrontFacing = (label = '') => {
        const label_lower = label.toLowerCase();
        return label_lower.includes('front') || 
               label_lower.includes('user') || 
               label_lower.includes('face') ||
               !label_lower.includes('back');  // Default to front if unclear
      };

      if (isSafari && !videoDevices[0].label) {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const devicesAfterPermission = await navigator.mediaDevices.enumerateDevices();
        const videoDevicesWithLabels = devicesAfterPermission.filter(
          device => device.kind === 'videoinput'
        );
        setCameras(videoDevicesWithLabels);
        if (videoDevicesWithLabels.length > 0) {
          const defaultCamera = videoDevicesWithLabels[0];
          setSelectedCamera(defaultCamera.deviceId);
          setIsFrontFacing(detectFrontFacing(defaultCamera.label));
        }
        return stream;
      }

      setCameras(videoDevices);
      if (videoDevices.length > 0) {
        const defaultCamera = videoDevices[0];
        setSelectedCamera(defaultCamera.deviceId);
        setIsFrontFacing(detectFrontFacing(defaultCamera.label));
      }
    } catch (error) {
      console.error("Error enumerating devices:", error);
      setError(error.message || 'Failed to list cameras');
      return null;
    }
  };

  const requestCameraPermission = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let stream;
      
      stream = await enumerateDevices();
      
      if (!stream) {
        
        stream = await navigator.mediaDevices.getUserMedia({
          video: selectedCamera ? { deviceId: { exact: selectedCamera } } : true
        });
      }

      setHasPermission(true);

      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }

    } catch (error) {
      setError(error.message || 'Failed to access camera');
      setHasPermission(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleDeviceChange = () => {
      if (hasPermission) {
        enumerateDevices();
      }
    };

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
    };
  }, [hasPermission]);

  useEffect(() => {
    if (isCameraOpen) {
      requestCameraPermission();
    }
  }, [isCameraOpen]);

  const handleCameraChange = async (event) => {
    const newCameraId = event.target.value;
    setSelectedCamera(newCameraId);
    setError(null);

    try {
      const currentStream = videoRef.current?.srcObject;
      if (currentStream) {
        currentStream.getTracks().forEach(track => track.stop());
      }

      // Find the selected camera device
      const selectedDevice = cameras.find(camera => camera.deviceId === newCameraId);
      const isFront = selectedDevice ? 
        selectedDevice.label.toLowerCase().includes('front') || 
        selectedDevice.label.toLowerCase().includes('user') ||
        !selectedDevice.label.toLowerCase().includes('back') : true;
      
      setIsFrontFacing(isFront);

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: newCameraId } }
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Camera switch error:", error);
      setError('Failed to switch camera');
    }
  };

  const stopCameraStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const handleClose = () => {
    stopCameraStream();
    setIsCameraOpen(false);
  };

  useEffect(() => {
    return () => {
      stopCameraStream();
    };
  }, []);

  useEffect(() => {
    if (isCameraOpen && selectedCamera) {
      const openCamera = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: selectedCamera } }
          });
          videoRef.current.srcObject = stream;
        } catch (error) {
          console.error("Camera access error:", error);
        }
      };
      openCamera();
    } else if (!isCameraOpen) {
      stopCameraStream();
    }
  }, [isCameraOpen, selectedCamera]);

  const handleCapturePhoto = () => {
    const video = videoRef.current;  // Use the ref instead of querySelector
    const canvas = document.createElement("canvas");
    
    // Set canvas dimensions to match video dimensions
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    
    const context = canvas.getContext("2d");
    
    // Clear the canvas first
    context.clearRect(0, 0, canvas.width, canvas.height);
    
    // Handle mirroring for front-facing camera
    if (isFrontFacing) {
      context.scale(-1, 1);
      context.drawImage(video, -canvas.width, 0, canvas.width, canvas.height);
    } else {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
    }
    
    // Convert to PNG with maximum quality
    const photo = canvas.toDataURL("image/png", 1.0);
    onCapturePhoto(photo);
    stopCameraStream();
    setIsCameraOpen(false);
  };

  if (!isCameraOpen) return null;

  return (
    <Dialog open={isCameraOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className="bg-white flex justify-between items-center">
        Take a photo
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="bg-white">
        {!hasPermission ? (
          <div className="flex flex-col items-center justify-center p-4">
            <p className="mb-4 text-center">Camera permission is required to take photos</p>
            {error && (
              <p className="mb-4 text-red-500 text-center">{error}</p>
            )}
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded-md"
              onClick={requestCameraPermission}
              disabled={isLoading}
            >
              {isLoading ? 'Requesting Access...' : 'Allow Camera Access'}
            </button>
          </div>
        ) : (
          <>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className={`w-full h-auto ${isFrontFacing ? 'transform scale-x-[-1]' : ''}`}
            />
            {cameras.length > 0 && (
              <div className="flex-1 mt-4 flex items-center justify-center">
                <FormControl variant="outlined" size="small" className="min-w-[200px]">
                  <InputLabel>Select Camera</InputLabel>
                  <Select
                    value={selectedCamera}
                    onChange={handleCameraChange}
                    label="Select Camera"
                  >
                    {cameras.map((camera) => (
                      <MenuItem key={camera.deviceId} value={camera.deviceId}>
                        {camera.label || `Camera ${cameras.indexOf(camera) + 1}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div className="mt-4 flex justify-around">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded-md"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md"
                onClick={handleCapturePhoto}
              >
                Capture
              </button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CameraPhotoCaptureDialog;
