import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHotkeys } from "react-hotkeys-hook";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const DisplayImagesInPost = ({ filesSrc = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0); // Prati trenutni aktivni slide

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  const imageWidth = "140px";
  const imageHeight = "80px";
  const imageWidthMobile = "100px";
  const imageHeightMobile = "60px";

  const maxWidth = "600px";
  const maxWidthMobile = "295px";

  useHotkeys("esc", () => {
    if (isFullScreen) {
      setIsFullScreen(false);
    }
  });

  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = "hidden"; // stop the scroll with keyboard or mouse wheel in the full screen mode
    } else {
      document.body.style.overflow = "";
    }

    // remove the effect if we remove the component
    return () => {
      document.body.style.overflow = "";
    };
  }, [isFullScreen]);

  const [reorderedSlides, setReorderedSlides] = useState([]);

  useEffect(() => {
    // Proveri i ažuriraj slides kada se activeIndex ili filesSrc promene
    if (filesSrc && filesSrc.length > 0) {
      const updatedSlides = filesSrc
        .slice(activeIndex % filesSrc.length)
        .concat(filesSrc.slice(0, activeIndex % filesSrc.length))
        .map((imageSrc) => ({ src: imageSrc }));
      setReorderedSlides(updatedSlides);
    } else {
      setReorderedSlides([]); // Prazan niz ako nema slika
    }
  }, [activeIndex, filesSrc]); // Pokreće se kada se promeni activeIndex ili filesSrc

  return (
    <Box>
      {/* Main Image */}
      {filesSrc.length > 0 && (
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            aspectRatio: "1 / 1",
            margin: "0 auto",
            overflow: "hidden",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
          onClick={toggleFullScreen}
        >
          <img
            src={filesSrc[activeIndex]}
            alt="Active Image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      )}

      {/* Thumbnails Carousel */}
      {filesSrc.length > 1 && (
        <Grid item xs={12}>
          <Swiper
            modules={[Navigation, Keyboard]}
            slidesPerView="3"
            spaceBetween={8}
            centeredSlides={true}
            navigation={true}
            keyboard={{ enabled: true, onlyInViewport: true }}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)} // Ažurira aktivni indeks
            style={{
              maxWidth: !isMobile ? maxWidth : maxWidthMobile,
              margin: "0 auto",
            }}
          >
            {filesSrc.map((src, index) => (
              <SwiperSlide
                key={index}
                style={{
                  width: !isMobile ? imageWidth : imageWidthMobile,
                  height: !isMobile ? imageHeight : imageHeightMobile,
                }}
                onClick={() => {
                  setActiveIndex(index);
                }}
              >
                <img
                  src={src}
                  alt={`Thumbnail ${index}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      )}

      {/* Full Screen Swiper */}
      <Lightbox
        open={isFullScreen}
        plugins={[Zoom]}
        close={() => setIsFullScreen(false)}
        slides={reorderedSlides}
        zoom={{
          maxZoomPixelRatio: 3,
          zoomInMultiplier: 2,
          doubleTapDelay: 300,
          doubleClickDelay: 300,
          doubleClickMaxStops: 2,
          wheelZoomDistanceFactor: 100,
          pinchZoomDistanceFactor: 100,
          scrollToZoom: true,
        }}
      />
    </Box>
  );
};

export default DisplayImagesInPost;
