import { createSlice } from "@reduxjs/toolkit";
import {
  ChangeAdminPasswordAction,
  LoginAdminAction,
} from "../../../middlewares/admin/session/auth";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginLoading: false,
    loginMessage: "",
    loginError: "",
    loggedInUser: null,
    isTokenValid: true,
    changePasswordMessage: "",
    changePasswordError: false,
    changePasswordSuccess: false,
  },
  reducers: {
    resetLogin: (state) => {
      state.loginLoading = false;
      state.loginMessage = "";
      state.loginError = "";
      state.loggedInUser = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(LoginAdminAction.pending, (state) => {
        state.loginLoading = true;
        state.loginMessage = "";
        state.loginError = "";
      })
      .addCase(LoginAdminAction.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.loggedInUser = action.payload.response;
        state.loginMessage = action.payload.message;
      })
      .addCase(LoginAdminAction.rejected, (state, action) => {
        state.loginLoading = false;
        state.loginError = action.payload.message;
        state.loggedInUser = null;
      })
      .addCase(ChangeAdminPasswordAction.pending, (state) => {
        state.changePasswordMessage = "";
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
      })
      .addCase(ChangeAdminPasswordAction.fulfilled, (state, action) => {
        state.changePasswordMessage = action.payload.message;
        state.changePasswordError = false;
        state.changePasswordSuccess = true;
      })
      .addCase(ChangeAdminPasswordAction.rejected, (state, action) => {
        state.changePasswordMessage = action.payload.message;
        state.changePasswordError = true;
        state.changePasswordSuccess = false;
      });
  },
});

export const { resetLogin } = authSlice.actions;

export default authSlice.reducer;
