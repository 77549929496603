import { axiosAuthClient } from "../../../../utils/api";

export const register = async (payload) => {
  try {
    const response = await axiosAuthClient.request({
      url: "/auth/register",
      method: "post",
      data: payload,
    });
    
    return response.data;
  } catch (err) {
    let tempObj = { 
      status: 0,
      message: err?.response?.data?.message || "Registration failed",
      response: null
    };
    return tempObj;
  }
};
