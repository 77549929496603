import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import ShowActivity from "./../../../../../../assets/images/show-activity.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateActivityStatusAction } from "../../../../../../store/middlewares/web/profile/myProfile/me";

const ModalProfilePrivacy = () => {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.me);

  const handleActivityChange = (event) => {
    dispatch(updateActivityStatusAction({ showActivity: event.target.checked }));
  };

  return (
    <div className="gray-border border-radius-10 p-3">
      <p className="font-20 font-medium text-darkblue mb-3">Profile</p>
      <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3">
        <div className="flex items-start gap-4 w-full">
          <img src={ShowActivity} alt="ShowActivity" />
          <div>
            <p className="font-18 font-medium text-darkblue ">
              Show Activity Status
            </p>
          </div>
        </div>
        <FormControlLabel
          className="black-border"
          control={
            <Switch
              checked={me?.isActivityStatusEnabled || false}
              onChange={handleActivityChange}
              name="activityStatus"
              color="primary"
            />
          }
        />
      </div>
    </div>
  );
};

export default ModalProfilePrivacy;
