import React, { useState, useEffect } from "react";
import UserBlank from "./../../../../../../assets/images/blank.png";
import { timeAgo } from "../../../../../../utils/timeAgo";
import StoryModal from "../index";
import LikePost from "../../../../../atoms/LikePost";
import SendTipButton from "../../SendTipButton";
import ChatIconButton from "./ChatIconButton";
import { Box } from "@mui/material";
import { STORY_DURATION } from "../../../../../../constants";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../../store/slices/common/modal";
import ModalDeleteStoryMedia from "../../ModalDeleteStoryMedia";
import { getStoriesAction, likeStoryPostMediaAction } from "../../../../../../store/middlewares/web/post/posts";
import { useSelector } from "react-redux";

const ProgressBars = ({ totalImages, currentIndex, progress }) => (
  <div className="flex w-full mb-2">
    {[...Array(totalImages)].map((_, index) => (
      <div key={index} className="h-1 bg-gray-300 flex-grow mx-0.5">
        {index === currentIndex && (
          <div
            className="h-full bg-blue-500 transition-all duration-100"
            style={{ width: `${progress}%` }}
          />
        )}
        {index < currentIndex && (
          <div
            className="h-full bg-blue-500 transition-all duration-100"
            style={{ width: `100%` }}
          />
        )}
      </div>
    ))}
  </div>
);

const StoryContent = ({ imageUrl, onNext, onPrevious, onDelete, isCreator, onHoldStart, onHoldEnd, onLike, hasLiked, postId }) => {
  const handleMouseDown = () => {
    onHoldStart();
  };

  const handleMouseUp = () => {
    onHoldEnd();
  };

  const handleTouchStart = () => {
    onHoldStart();
  };

  const handleTouchEnd = () => {
    onHoldEnd();
  };

  const handleClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    
    // If click is on the left 1/3 of the image, go to previous
    if (x < width / 3) {
      onPrevious();
    } 
    // If click is on the right 2/3 of the image, go to next
    else {
      onNext();
    }
  };

  return (
    <div className="relative w-full h-full aspect-[4/5]">
      <img
        src={imageUrl}
        alt="Story content"
        className="absolute inset-0 w-full h-full object-cover rounded-2xl"
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      />
      <div className="absolute bottom-4 left-4 flex gap-2 items-center">
        {isCreator && (
          <div 
            className="bg-red-500 text-white px-3 py-1 rounded cursor-pointer hover:bg-red-600"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            DELETE
          </div>
        )}
        <div onClick={(e) => e.stopPropagation()}>
          <LikePost
            likesCount={0}
            hasLiked={hasLiked}
            isDisabled={false}
            postId={postId}
            showCounter={false}
            handleLikeProp={onLike}
          />
        </div>
      </div>
    </div>
  );
};

const StoryViewer = ({
  images,
  onClose,
  user,
  createdAt,
  hasLiked,
  likesCount,
  postId,
  allStories
}) => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [localImages, setLocalImages] = useState(images);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isHolding, setIsHolding] = useState(false);

  const me = useSelector((state) => state.me.me);

  const getCurrentStoryAndMedia = () => {
    let mediaCount = 0;
    for (const story of allStories) {
      const storyMediaCount = story.postMedia.length;
      if (currentIndex < mediaCount + storyMediaCount) {
        const mediaIndex = currentIndex - mediaCount;
        return {
          story,
          media: story.postMedia[mediaIndex],
          mediaIndex
        };
      }
      mediaCount += storyMediaCount;
    }
    return null;
  };

  const handleOnNext = () => {
    setProgress(0);
    if (currentIndex < localImages.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      onClose();
    }
  };

  const handleOnPrevious = () => {
    if (currentIndex > 0) {
      setProgress(0);
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleLike = () => {
    const current = getCurrentStoryAndMedia();
    if (current) {
      dispatch(likeStoryPostMediaAction({ 
        postId: current.story.id, 
        postMediaId: current.media.id 
      }));
    }
  };

  const handleDelete = () => {
    const current = getCurrentStoryAndMedia();
    if (!current) return;

    setIsDeleteDialogOpen(true);
    dispatch(
      openModal({
        title: "DELETE",
        hasCloseBtn: true,
        content: (
          <ModalDeleteStoryMedia
            postId={current.story.id}
            mediaId={current.media.id}
            onSuccess={() => {
              // Remove the deleted media from localImages
              const newImages = localImages.filter((_, index) => index !== currentIndex);
              setLocalImages(newImages);
              dispatch(getStoriesAction());
              if (newImages.length === 0) {
                onClose();
              } else if (currentIndex >= newImages.length) {
                setCurrentIndex(newImages.length - 1);
              }
              setIsDeleteDialogOpen(false);
            }}
            onCancel={() => {
              setIsDeleteDialogOpen(false);
            }}
          />
        ),
      })
    );
  };

  useEffect(() => {
    if (localImages.length === 0) {
      onClose();
      return;
    }

    let timer;
    if (!isDeleteDialogOpen && !isHolding) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            if (currentIndex < localImages.length - 1) {
              setCurrentIndex((prevIndex) => prevIndex + 1);
              return 0;
            } else {
              clearInterval(timer);
              onClose();
              return 100;
            }
          }
          return prevProgress + 100 / (STORY_DURATION / 100);
        });
      }, 100);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [currentIndex, localImages.length, onClose, isDeleteDialogOpen, isHolding]);

  if (localImages.length === 0) {
    return null;
  }

  const current = getCurrentStoryAndMedia();

  return (
    <StoryModal
      username={user.username}
      date={timeAgo(current?.story.createdAt || createdAt)}
      profileImage={user.profileImage || UserBlank}
      ProgressBarComponent={
        <ProgressBars
          totalImages={localImages.length}
          currentIndex={currentIndex}
          progress={progress}
        />
      }
      handleCancel={onClose}
    >
      <>
        <div className="flex-grow overflow-hidden relative">
          <StoryContent
            imageUrl={localImages[currentIndex].fileURL}
            onNext={handleOnNext}
            onPrevious={handleOnPrevious}
            onDelete={handleDelete}
            isCreator={me?.id === user.id}
            onHoldStart={() => setIsHolding(true)}
            onHoldEnd={() => setIsHolding(false)}
            onLike={handleLike}
            hasLiked={current?.media.hasLiked}
            postId={current?.story.id}
          />
        </div>
      </>
      <div className="flex justify-end my-2 mr-2 mt-4">
        <Box className="mr-2">
          <ChatIconButton />
        </Box>

        <Box>
          <SendTipButton />
        </Box>
      </div>
    </StoryModal>
  );
};

export default StoryViewer;
