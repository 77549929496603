
import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateNotifications, getNotificationsPreferences, getNotifications } from "../../../../services/web/profile/notifications.services";

export const getNotificationsAction = createAsyncThunk("notifications/getNotifications", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await getNotifications(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})


export const updateNotificationsAction = createAsyncThunk("notifications/updateNotifications", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await updateNotifications(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue(response);
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const getNotificationsPreferencesAction = createAsyncThunk("notifications/getNotificationsPreferences", async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await getNotificationsPreferences(payload);
        return fulfillWithValue(response);
    } catch (error) {
        return rejectWithValue(error);
    }
})