import { createSlice } from "@reduxjs/toolkit";
import { getVerificationRequestsAction } from "../../../middlewares/admin/verification/verification";

const initialState = {
  loading: false,
  verificationRequests: [],
  nextCursor: null,
  error: null,
};

export const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    clearVerificationRequests: (state) => {
      state.verificationRequests = [];
      state.nextCursor = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getVerificationRequestsAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;

        console.log("action.meta.arg.cursor : ", action.meta.arg.cursor);
        if (!action.meta.arg.cursor) {
          state.verificationRequests = [];
          state.nextCursor = null;
        }
      })
      .addCase(getVerificationRequestsAction.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action.payload.response.users : ", action.payload.response.users);
        console.log("state.verificationRequests : ", state.verificationRequests);
        if (action.payload.status === 1) {
          console.log("action.meta.arg.cursor : ", action.meta.arg.cursor);
          
          if (action.meta.arg.cursor) {
            console.log("Appending data:", {
              existing: state.verificationRequests.length,
              new: action.payload.response.users.length,
              total: state.verificationRequests.length + action.payload.response.users.length
            });
            
            state.verificationRequests = [
              ...state.verificationRequests,
              ...action.payload.response.users
            ];
            
            console.log("After append - total items:", state.verificationRequests.length);
          } else {
            
            console.log("Replacing data:", {
              new: action.payload.response.users.length
            });
            state.verificationRequests = action.payload.response.users;
          }
          state.nextCursor = action.payload.response.nextCursor;
        } else {
          state.error = action.payload.message || "Failed to fetch verification requests";
        }
      })
      .addCase(getVerificationRequestsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch verification requests";
      });
  },
});

export const { clearVerificationRequests } = verificationSlice.actions;
export default verificationSlice.reducer;
