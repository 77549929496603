import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import GoogleIcon from "../../../../assets/images/google.svg";
import AppleIcon from "../../../../assets/images/apple.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { loginAction } from "../../../../store/middlewares/auth/session/auth";
import axiosClient from "../../../../utils/api";

function SocialMediaButtons({ ip }) {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Google Login Failed:", error),
  });

  useEffect(() => {
    const loadAppleSDK = () => {
      const script = document.createElement("script");
      script.src =
        "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
      script.onload = () => {
        if (window.AppleID) {
          window.AppleID.auth.init({
            // eslint-disable-next-line no-undef
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            scope: "name email",
            // eslint-disable-next-line no-undef
            redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
            usePopup: true,
          });
        }
      };
      document.head.appendChild(script);
    };

    if (!window.AppleID) {
      loadAppleSDK();
    }
  }, []);

  const appleLogin = async () => {
    try {
      const response = await window.AppleID.auth.signIn();
      const { id_token } = response.authorization;
      dispatch(
        loginAction({
          token: id_token,
          type: "apple",
          password: "YouOnly123!",
        })
      );
    } catch (error) {
      console.error("Apple Login Failed", error);
    }
  };

  useEffect(() => {
    if (user) {
      axiosClient
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
            withCredentials: false,
          }
        )
        .then((res) => {
          dispatch(
            loginAction({
              token: user.access_token,
              type: "google",
              password: "YouOnly123!",
              name: res.data.name,
              email: res.data.email,
            })
          );
        })
        .catch((err) => console.log(err));
    }
  }, [user, dispatch, ip]);

  return (
    <div className="flex gap-4 mt-8">
      <Button variant="outlined" className="w-full" onClick={googleLogin}>
        <img src={GoogleIcon} alt="google" width={27} className="mr-3" /> Google
      </Button>
      <Button variant="outlined" className="w-full" onClick={appleLogin}>
        <img src={AppleIcon} alt="Apple" width={27} className="mr-3" /> Apple ID
      </Button>
    </div>
  );
}

export default SocialMediaButtons;
