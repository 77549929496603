import React from 'react';
import styles from './styles.module.css';
import Timeline from './Timeline';
import PlayButton from '../../../../../../assets/SVG/media/videoPlayer/PlayButton';
import PauseButton from '../../../../../../assets/SVG/media/videoPlayer/PauseButton';
import FullScreenButton from '../../../../../../assets/SVG/media/videoPlayer/FullScreenButton';

const MobileControls = ({
  isPlaying,
  currentTime,
  duration,
  onPlay,
  onTimelineChange,
  onFullscreenToggle,
  formatTime,
  showControls,
}) => {
  const buttonSize = 32; 

  return (
    <div className={`${styles.mobileControls} ${showControls ? styles.visible : ''}`}>
      <div className={styles.mobileControlsOverlay}>
        <div className={styles.mobileButtonsContainer}>
          <button
            className={`${styles.mobileControlButton} ${styles.mobilePlayButton}`}
            onClick={onPlay}
            aria-label={isPlaying ? "Pause" : "Play"}
          >
            {isPlaying ? 
              <PauseButton height={buttonSize} width={buttonSize}/> : 
              <PlayButton height={buttonSize} width={buttonSize}/>
            }
          </button>
        </div>

        <div className={styles.mobileTimelineContainer}>
          <Timeline
            currentTime={currentTime}
            duration={duration}
            onTimelineChange={onTimelineChange}
            formatTime={formatTime}
            isMobile={true}
          />
        </div>

        <button
          className={`${styles.mobileControlButton} ${styles.mobileFullscreenButton}`}
          onClick={onFullscreenToggle}
          aria-label="Toggle Fullscreen"
        >
          <FullScreenButton height={buttonSize * 0.4} width={buttonSize * 0.4}/>
        </button>
      </div>
    </div>
  );
};

export default MobileControls;
