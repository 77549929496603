import { TabContext, TabList } from "@mui/lab";
import { Box, Button, CircularProgress, Tab } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RepeatCircle from "./../../../../../assets/images/repeat-circle.svg";
import { HomeSingleSuggestion } from "../../../../organisms";
import { useDispatch, useSelector } from "react-redux";
import { getSuggestionsAction } from "../../../../../store/middlewares/web/suggestions/suggestions";
import { languages } from "../../../../../utils/languages";

const SuggestionsFullMenu = ({
  hasTab = true,
  hasRepeat = true,
  title = "Suggestion",
}) => {
  const dispatch = useDispatch();
  const { suggestions, suggestionsLoading } = useSelector(
    (state) => state.suggestions
  );
  const { me } = useSelector((state) => state.me);
  const [planTypeFilter, setPlanTypeFilter] = useState("all");

  const { pathname } = useLocation();
  const userType = pathname.split("/")[1];

  const navigate = useNavigate();

  const getSuggestion = useCallback(() => {
    dispatch(getSuggestionsAction({ pagination: true, planTypeFilter }));
  }, [dispatch, planTypeFilter]);

  useEffect(() => {
    getSuggestion();
  }, [getSuggestion]);

  const handleChangeTab = (event, newValue) => {
    setPlanTypeFilter(newValue);
  };

  const handleShuffle = () => {
    getSuggestion();
  };

  return (
    <div className={`bg-white rounded-2xl p-4 mb-3 ${me && !me.isIdentityVerified ? 'mt-12' : ''}`}>
      <div className="flex justify-between items-center mb-2 gap-2 ">
        <div className="flex flex-wrap items-center gap-2">
          <h3 className="uppercase font-semibold font-18 text-darkblue mb-2">
            {languages["en"][`${title}`]}
          </h3>
          {hasTab && (
            <div className="post-tab mb-2">
              <TabContext value={planTypeFilter}>
                <Box>
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="simple tabs example"
                  >
                    <Tab label={languages["en"]?.All} value="all" />
                    <Tab label={languages["en"]?.Free} value="free" />
                  </TabList>
                </Box>
              </TabContext>
            </div>
          )}
        </div>
        <div className="flex gap-2">
          {hasRepeat && (
            <Link href="#">
              <img
                src={RepeatCircle}
                alt="RepeatCircle"
                width={28}
                onClick={handleShuffle}
                className="img-dark-filter"
              />
            </Link>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center h-auto">
        {" "}
        {/* Adjust height as needed */}
        {suggestionsLoading ? (
          <CircularProgress />
        ) : (
          suggestions.map((suggestion, index) => (
            <HomeSingleSuggestion suggestion={suggestion} key={index} />
          ))
        )}
      </div>
      <div className="mt-3">
        <Button
          variant="outlined"
          className="light-blue-btn w-full"
          onClick={() => navigate(`/all-suggestions`)}
        >
          {languages["en"]?.SeeAll}
        </Button>
      </div>
    </div>
  );
};

export default SuggestionsFullMenu;
