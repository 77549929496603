import React from "react";
import Verified from "./../../../../../assets/images/verified.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import UserProfileImage from "./UserProfileImage";

function Postheader({ img, name, uname, isVerified = true, isLoggedInUser, id }) {
  const navigate = useNavigate();
  const [triggerNav, setTriggerNav] = useState(false);
  useEffect(() => {
    if (triggerNav) {
      if (isLoggedInUser) {
        navigate("/home");
      } else {
        navigate(`/profile/${uname}`);
      }
    }
  }, [triggerNav]);

  const handlePostClick = () => {
    //trigger on Click to visit the user profile
    setTriggerNav(true);
  };

  return (
    <div className="flex gap-5 varified">
      <UserProfileImage 
        src={img} 
        name={name} 
        isVerified={isVerified} 
        onClick={handlePostClick}
      />
      <div
        className="flex flex-col justify-center cursor-pointer"
        onClick={handlePostClick}
      >
        <h3>{name}</h3>
        {uname && <p>@{uname}</p>}
      </div>
    </div>
  );
}

export default Postheader;
