import { createSlice } from "@reduxjs/toolkit";
import { getNotificationsAction, updateNotificationsAction, getNotificationsPreferencesAction } from "../../../../middlewares/web/profile/myProfile/notifications";

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: {
        itemsLoading: false,
        itemsLoadingMessage: "",
        itemsLoadingSuccess: false,
        itemsLoadingError: false,
        items: null,
        notificationsList: [],
        actionsSuccess: false,
        actionsError: false,
        actionsMessage: "",
        hasNextPage: false,
        nextCursor: null,
    },
    reducers: {
        reset: (state) => {
            state.itemsLoading = false;
            state.itemsLoadingMessage = "";
            state.itemsLoadingError = "";
            state.items = null;
            state.notificationsList = [];
            state.actionsSuccess = false;
            state.actionsError = false;
            state.actionsMessage = "";
            state.hasNextPage = false;
            state.nextCursor = null;
        },
        setFilteredNotifications: (state, action) => {
            const { notifications, nextCursor, hasNextPage, append } = action.payload;
            state.itemsLoading = false;
            if (append) {
                state.notificationsList = [...state.notificationsList, ...notifications];
            } else {
                state.notificationsList = notifications;
            }
            state.nextCursor = nextCursor;
            state.hasNextPage = hasNextPage;
        },
        setNotificationsLoading: (state, action) => {
            state.itemsLoading = action.payload;
        },
        resetNotificationsList: (state) => {
            state.notificationsList = [];
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getNotificationsPreferencesAction.pending, (state) => {
                state.itemsLoading = true;
                state.itemsLoadingMessage = "";
                state.itemsLoadingSuccess = false;
                state.itemsLoadingError = false;
                state.items = []
            })
            .addCase(getNotificationsPreferencesAction.fulfilled, (state, action) => {
                state.itemsLoading = true;
                state.itemsLoadingSuccess = true;
                state.itemsLoadingError = false;
                state.items = action.payload.response;
                state.itemsLoadingMessage = action.payload.message;
            })
            .addCase(getNotificationsPreferencesAction.rejected, (state, action) => {
                state.itemsLoading = true;
                state.itemsLoadingSuccess = false;
                state.itemsLoadingError = true;
                state.items = [];
                state.itemsLoadingMessage = action.payload.message;
            })
        builder
            .addCase(updateNotificationsAction.pending, (state) => {
                state.actionsSuccess = false;
                state.actionsError = false;
                state.actionsMessage = "";
                return state
            })
            .addCase(updateNotificationsAction.fulfilled, (state, action) => {
                let { items } = state
                let tempObj = { ...items }
                let { data } = action.payload
                tempObj = { ...items, ...data }
                state.actionsSuccess = true;
                state.actionsError = false;
                state.items = tempObj;
                state.actionsMessage = action.payload.message
                return state
            })
            .addCase(updateNotificationsAction.rejected, (state, action) => {
                state.actionsSuccess = false;
                state.actionsError = true;
                state.actionsMessage = action.payload.message;
            })
    }
});

export const { reset, setFilteredNotifications, setNotificationsLoading } = notificationsSlice.actions;

export default notificationsSlice.reducer;
