import React from "react";
import StoryViewer from "../../../newComponents/molecules/web/common/StoryModal/StoryViewer";
import UserBlank from "../../../assets/images/blank.png";
import { createPostView } from "../../../store/services/web/post/posts.services";

const HomeStories = ({ story }) => {
  const { postMedia, users, hasViewed, allStories } = story;
  const [isViewed, setIsViewed] = React.useState(hasViewed);
  const [openStory, setOpenStory] = React.useState(false);

  const handleOnStoryClick = () => {
    if (!isViewed) {
      setIsViewed(true);
      allStories.forEach(story => {
        if (!story.hasViewed) {
          createPostView(story.id);
        }
      });
    }
    setOpenStory(true);
  };

  const handleOnStoryClose = () => {
    setOpenStory(false);
  };

  return (
    <>
      <div
        className="user-img relative cursor-pointer"
        onClick={handleOnStoryClick}
      >
        <img src={postMedia[0].fileURL} alt={users.username} />
        <img
          src={users?.profileImage ?? UserBlank}
          alt={users.username}
          className={`user-position ${!isViewed && "active"}`}
        />
        <p className="mb-0 user-text" title={users.username}>
          {users.username}
        </p>
      </div>
      {openStory && (
        <StoryViewer
          images={allStories.reduce((acc, story) => [...acc, ...story.postMedia], [])}
          onClose={handleOnStoryClose}
          user={users}
          createdAt={story.createdAt}
          hasLiked={story.hasLiked}
          likesCount={story.likesCount}
          postId={story.id}
          allStories={allStories}
        />
      )}
    </>
  );
};

export default HomeStories;
