import { createSlice } from "@reduxjs/toolkit";
import LocalStorageService from "./../../../../utils/localstorage-services";
import {
  loginAction,
  verifyTOTPAction,
  logoutAction,
  verifyIdentityAction,
} from "../../../middlewares/auth/session/auth";
import { checkStoredAuthAction } from "../../../middlewares/auth/session/checkAuth";
import { encryptAndStoreToken } from "../../../../utils/CryptoJS";

export const webauthSlice = createSlice({
  name: "webauth",
  initialState: {
    webloginLoading: false,
    webloginMessage: "",
    webloginError: false,
    webloginSuccess: false,
    webloggedInUser: JSON.parse(localStorage.getItem("user-details")) || null,
    twoFaLoading: false,
    twoFaError: false,
    twoFaMessage: "",
    twoFaSuccess: false,
    tempToken: localStorage.getItem('temp_2fa_token') || null,
    requires2FA: JSON.parse(localStorage.getItem('requires_2fa')) || null,
    currentVerificationStep: null, // 'app' | 'sms' | null
    weblogoutLoading: false,
    weblogoutMessage: "",
    weblogoutError: "",
    weblogoutUser: null,
    isTokenValid: true,
    isAdminTokenValid: true,
    verifyIdentityLoading: false,
    verifyIdentityMessage: "",
    verifyIdentityError: "",
    verifyIdentitySuccess: false,
  },
  reducers: {
    resetweblogin: (state) => {
      state.webloginLoading = false;
      state.webloginMessage = "";
      state.webloginError = false;
      state.webloginSuccess = false;
      state.webloggedInUser = null;
      state.tempToken = null;
      state.requires2FA = null;
      localStorage.removeItem('temp_2fa_token');
      localStorage.removeItem('requires_2fa');
    },
    resetTwoFa: (state) => {
      state.twoFaLoading = false;
      state.twoFaError = false;
      state.twoFaMessage = "";
      state.twoFaSuccess = false;
    },
    weblogoutSuccess: (state, action) => {
      let { message } = action.payload;
      LocalStorageService.removeFromLocalStorage("user-details");
      state.weblogoutLoading = false;
      state.weblogoutMessage = message;
      state.weblogoutError = "";
    },
  },
  extraReducers(builder) {
    builder
      // Login
      .addCase(loginAction.pending, (state) => {
        state.webloginLoading = true;
        state.webloginMessage = "";
        state.webloginError = false;
        state.webloggedInUser = null;
        state.tempToken = null;
        state.requires2FA = null;
        state.webloginSuccess = false;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.webloginLoading = false;
        state.webloginError = false;
        
        // is2FA is required
        if (action.payload.message === "Two-factor authentication required") {
          state.requires2FA = action.payload.response.requires2FA;
          state.tempToken = action.payload.response.tempToken;
          state.webloginMessage = action.payload.message;
          state.webloggedInUser = null;
          state.currentVerificationStep = action.payload.response.requires2FA.app ? 'app' : 'sms';
          

          localStorage.setItem('temp_2fa_token', action.payload.response.tempToken);
          localStorage.setItem('requires_2fa', JSON.stringify(action.payload.response.requires2FA));
        } else if (action.payload.status === 1) {
          // Normal login success
          const userData = {
            ...action.payload.response,
            token: encryptAndStoreToken(action.payload.response.token)
          };

          state.webloggedInUser = userData;
          state.webloginMessage = action.payload.message;
          state.requires2FA = null;
          state.tempToken = null;
          state.currentVerificationStep = null;
          state.webloginSuccess = true;
          
          LocalStorageService.loginWeb(userData);
          
          localStorage.removeItem('temp_2fa_token');
          localStorage.removeItem('requires_2fa');
        }
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.webloginLoading = false;
        state.webloginError = true;
        state.webloginSuccess = false;
        state.webloginMessage = action.payload?.message || "Login failed";
        state.requires2FA = null;
        state.tempToken = null;
      })
      .addCase(verifyTOTPAction.pending, (state) => {
        state.twoFaLoading = true;
        state.twoFaError = false;
        state.twoFaMessage = "";
      })
      .addCase(verifyTOTPAction.fulfilled, (state, action) => {
        state.twoFaLoading = false;
        state.twoFaError = false;
        state.twoFaMessage = action.payload.message || "";
        
        if (action.payload.status === 1 && action.payload.response?.token) {
          localStorage.removeItem('temp_2fa_token');
          localStorage.removeItem('requires_2fa');
          const userData = {
            ...action.payload.response,
            token: encryptAndStoreToken(action.payload.response.token)
          };
          
          state.webloggedInUser = userData;
          state.webloginMessage = action.payload.message;
          state.requires2FA = null;
          state.tempToken = null;
          state.currentVerificationStep = null;
          state.webloginSuccess = true;
          state.twoFaSuccess = true;
          
          LocalStorageService.loginWeb(userData);
          setTimeout(() => {
            window.location.href = '/home';
          }, 100);
        } 
        else if (action.payload.status === 1 && action.payload.response?.requires2FA) {
          const { tempToken, requires2FA } = action.payload.response;
          
          state.requires2FA = requires2FA;
          state.tempToken = tempToken;
          state.currentVerificationStep = 'sms';
          
          localStorage.setItem('temp_2fa_token', tempToken);
          localStorage.setItem('requires_2fa', JSON.stringify(requires2FA));
        }
      })
      .addCase(verifyTOTPAction.rejected, (state, action) => {
        state.twoFaLoading = false;
        state.twoFaError = true;
        state.twoFaMessage = action.payload?.message || "Verification failed";
      })
      // Verify Identity
      .addCase(verifyIdentityAction.pending, (state) => {
        state.verifyIdentityLoading = true;
        state.verifyIdentityMessage = "";
        state.verifyIdentityError = "";
        state.verifyIdentitySuccess = false;
      })
      .addCase(verifyIdentityAction.fulfilled, (state, action) => {
        state.verifyIdentityLoading = false;
        state.verifyIdentityMessage = action.payload.message;
        state.verifyIdentitySuccess = true;
      })
      .addCase(verifyIdentityAction.rejected, (state, action) => {
        state.verifyIdentityLoading = false;
        state.verifyIdentityError = action.payload.message;
        state.verifyIdentitySuccess = false;
      })
      // Check Stored Auth
      .addCase(checkStoredAuthAction.fulfilled, (state, action) => {
        state.isTokenValid = action.payload;
      })
      .addCase(checkStoredAuthAction.rejected, (state) => {
        state.isTokenValid = false;
      })
      // Logout
      .addCase(logoutAction.fulfilled, (state, action) => {
        LocalStorageService.removeFromLocalStorage("user-details");
        state.weblogoutLoading = false;
        state.weblogoutMessage = action.payload?.message || "Logged out successfully";
        state.weblogoutError = "";
        state.webloggedInUser = null;
        state.isTokenValid = false;
        window.location.href = '/login';
      })
      .addCase(logoutAction.rejected, (state, action) => {
        state.weblogoutLoading = false;
        state.weblogoutError = action.payload?.message || "Logout failed";
      })
      .addCase(logoutAction.pending, (state) => {
        state.weblogoutLoading = true;
      });
  },
});

export const { resetweblogin, resetTwoFa, weblogoutSuccess } =
  webauthSlice.actions;

export default webauthSlice.reducer;
