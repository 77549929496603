import React from "react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { likeDislikePost } from "../../../store/middlewares/web/post/posts";
import { HeartIcon } from "../../../assets/SVG/HeartIcon";
import { getColor } from "../../../utils/colors";

const LikePost = ({
  likesCount,
  hasLiked,
  isDisabled,
  postId,
  showCounter = true,
  handleLikeProp
}) => {
  const [liked, setLiked] = React.useState(hasLiked);
  const [likesCounter, setLikesCounter] = React.useState(likesCount);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLiked(hasLiked);
  }, [hasLiked]);

  const handleLike = () => {
    if (handleLikeProp) {
      setLiked((prev) => !prev);
      return handleLikeProp();
    }
    // handle Like/Dislike
    let reqObj = {
      postId: postId,
    };
    dispatch(likeDislikePost(reqObj));
    setLikesCounter((prev) => (liked ? prev - 1 : prev + 1));
    setLiked((prev) => !prev);
  };

  return (
    <div className="flex items-center gap-[4px]">
      <IconButton
        aria-label="delete"
        disabled={isDisabled}
        sx={{ padding: "0px !important" }}
        onClick={() => {
          handleLike();
        }}
      >
        {!liked ? (
          <HeartIcon type="outline" color={getColor("primary")} />
        ) : (
          <HeartIcon type="filled" color={getColor("primary")} />
        )}
      </IconButton>
      {showCounter && <p className="font-medium font-16">{likesCounter}</p>}
    </div>
  );
};

export default LikePost;
