import React from "react";
import { Grid, Typography, AvatarGroup, Avatar } from "@mui/material";
import GifIcon from "../../../../../../assets/SVG/VaultTabs/GifIcon.js";
import VideoIcon from "../../../../../../assets/SVG/VaultTabs/VideoIcon.js";
import PhotoIcon from "../../../../../../assets/SVG/VaultTabs/PhotoIcon.js";
import AudioIcon from "../../../../../../assets/SVG/VaultTabs/AudioIcon.js";
import { useDroppable } from "@dnd-kit/core";

const VaultCard = ({
  isSelected,
  vaultCollectionName,
  gifCount,
  videoCount,
  photoCount,
  audioCount,
  avatars,
  onClick,
  id,
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: id, // id foldera je droppable id
  });

  return (
    <Grid
      ref={setNodeRef}
      container
      direction="column"
      justifyContent="space-between"
      onClick={onClick}
      sx={{
        p: 1,
        mb: 2,
        borderRadius: 2,
        backgroundColor: isOver
          ? "#90CAF9"
          : isSelected
          ? "#2196F3"
          : "bg-white",
        color: isSelected ? "#FFFFFF" : "#000000",
        border: "1px solid",
        borderColor: isSelected ? "#2196F3" : "#E0E0E0",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: isSelected ? "#1976D2" : "#F5F5F5",
        },
        width: "100%",
        minHeight: 80,
      }}
    >
      <Grid container alignItems="center">
        {/* Naslov i statistika */}
        <Grid item xs={4}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{
              color: isSelected ? "#FFFFFF" : "#1E88E5",
            }}
          >
            {vaultCollectionName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          container
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
          sx={{ fontSize: "0.875rem" }}
        >
          {photoCount >= 0 && (
            <Grid item sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <PhotoIcon white={isSelected} />
              <Typography
                sx={{
                  color: isSelected ? "#FFFFFF" : "#1E88E5",
                }}
              >
                {photoCount}
              </Typography>
            </Grid>
          )}
          {videoCount >= 0 && (
            <Grid item sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <VideoIcon white={isSelected} />
              <Typography
                sx={{
                  color: isSelected ? "#FFFFFF" : "#1E88E5",
                }}
              >
                {videoCount}
              </Typography>
            </Grid>
          )}
          {audioCount >= 0 && (
            <Grid item sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <AudioIcon white={isSelected} />
              <Typography
                sx={{
                  color: isSelected ? "#FFFFFF" : "#1E88E5",
                }}
              >
                {audioCount}
              </Typography>
            </Grid>
          )}
          {gifCount >= 0 && (
            <Grid item sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <GifIcon white={isSelected} />
              <Typography
                sx={{
                  color: isSelected ? "#FFFFFF" : "#1E88E5",
                }}
              >
                {gifCount}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Avatar grupa ispod */}
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <AvatarGroup
            variant="rounded"
            max={3}
            total={avatars.length ?? 0}
            sx={{
              "& .MuiAvatarGroup-avatar": {
                width: 40,
                height: 25,
                borderRadius: 4,
                fontSize: "0.75rem", // Prilagođena veličina fonta za "+X"
                "&:last-child": {
                  marginLeft: -1, // Uklanjanje margine za poslednji element
                },
              },
            }}
          >
            {avatars.map((src, index) => (
              <Avatar key={index} src={src} />
            ))}
          </AvatarGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VaultCard;
