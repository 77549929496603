import axiosClient from "../../../../utils/api";

//Get User Wise Media
export const getMediaUserWise = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/getMediaByUser",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Media User Wise Service", error);
    throw error;
  }
};
