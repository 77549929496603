import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { resendIdentityVerificationEmailAction } from "../../../../../store/middlewares/auth/session/auth";
import { formatDuration } from "../../../../../utils/formatDuration";
import { EMAIL_COOLDOWN } from "../../../../../constants";

const CustomButton = styled('button')(({ theme }) => ({
  height: '24px',
  padding: '1px 10px',
  fontSize: '11px',
  margin: "3px 0px",
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.primary.contrastText,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderRadius: '4px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  transition: 'background-color 0.3s, color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  '&:disabled': {
    opacity: 0.7,
  }
}));

const EmailVerificationBanner = () => {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.me);
  const [countdown, setCountdown] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleResend = () => {
    if (countdown === 0) {
      dispatch(resendIdentityVerificationEmailAction(me.email, me.name));
      setCountdown(EMAIL_COOLDOWN);
    }
  };

  useEffect(() => {
    const lastSent = me?.lastIdentityVerificationEmailSent;
    if (lastSent) {
      const lastSentDate = new Date(lastSent);
      const now = new Date();
      const diff = now - lastSentDate;
      const remaining = EMAIL_COOLDOWN - diff;
      if (remaining > 0) {
        setCountdown(remaining);
      }
    }
  }, [me]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown((prevCountdown) => prevCountdown - 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);

  if (!me || me.isIdentityVerified) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "primary.main",
        color: "primary.contrastText",
        py: 1.5,
        px: 2,
        display: "flex",
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        textAlign: 'center',
      }}
    >
      <Typography 
        variant="body2"
        sx={{
          lineHeight: 1.2,
          fontSize: '12px'
        }}
      >
        Please verify your email address to access all features
      </Typography>
      <CustomButton
        onClick={handleResend}
        disabled={countdown > 0}
      >
        {countdown > 0
          ? `Resend in ${formatDuration(Math.floor(countdown / 1000))}`
          : "Resend verification email"}
      </CustomButton>
    </Box>
  );
};

export default EmailVerificationBanner;
