import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddCircleBlue from "../../../../../../assets/images/add-circle-blue.svg";
import PlusIcon from "../../../../../../assets/images/plus.svg";
import { createPosts } from "../../../../../../store/services/web/post/posts.services";
import BlankUser from "../../../../../../assets/images/blank.png";
import StoryModal from "..";
import {
  STORY_MAX_FILE_SIZE,
  STORY_MAX_IMAGES
} from "../../../../../../constants";
import { imageMimeTypes, acceptedFileTypes } from "../../../../../../constants/mimeTypes";
import { openToast } from "../../../../../../store/slices/common/toast";
import MediaMenuOptions from "../../DeviceCamera/MediaMenuOptions";
import CameraPhotoCaptureDialog from "../../DeviceCamera/CameraPhotoCaptureDialog";
import { getStoriesAction } from "../../../../../../store/middlewares/web/post/posts";
import { GetAllSettingsAction } from "../../../../../../store/middlewares/admin/settings/settings";

const ProgressBar = () => (
  <div className="h-1 bg-blue-500 mb-2 rounded-full w-3/10" />
);

const StoryContent = ({ images, currentIndex }) => {
  return (
    <div className="relative w-full h-full aspect-[4/5]">
      {images.map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`Story content ${index + 1}`}
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          } rounded-2xl`}
        />
      ))}
    </div>
  );
};

const ImageSwitcher = ({ totalImages, currentIndex, setCurrentIndex }) => (
  <div className="absolute top-4 left-0 right-0 flex justify-center space-x-1">
    {[...Array(totalImages)].map((_, index) => (
      <button
        key={index}
        onClick={() => setCurrentIndex(index)}
        className={`w-2 h-2 rounded-full ${
          index === currentIndex ? "bg-blue-500" : "bg-gray-300"
        }`}
      />
    ))}
  </div>
);

const NavigationArrows = ({ currentIndex, setCurrentIndex, totalImages }) => (
  <>
    {currentIndex > 0 && (
      <button
        onClick={() => setCurrentIndex((prev) => prev - 1)}
        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 text-gray-800 hover:bg-opacity-75 transition-all"
      >
        ←
      </button>
    )}
    {currentIndex < totalImages - 1 && (
      <button
        onClick={() => setCurrentIndex((prev) => prev + 1)}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 text-gray-800 hover:bg-opacity-75 transition-all"
      >
        →
      </button>
    )}
  </>
);

const ImageCounter = ({ currentIndex, totalImages }) => (
  <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-full text-sm">
    {currentIndex + 1} / {totalImages}
  </div>
);

const ActionButtons = ({ onPost, onAddMore, canAddMore }) => (
  <div className="p-2 flex justify-around items-center">
    {canAddMore && (
      <img
        src={AddCircleBlue}
        alt="AddCircleBlue"
        onClick={onAddMore}
        className="cursor-pointer"
      />
    )}
    <button
      onClick={onPost}
      className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-sm"
    >
      Post
    </button>
  </div>
);

const StoryCreator = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const fileInputRef = useRef(null);
  const { me } = useSelector((state) => state.me);
  const { items } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!!items?.postSettings){
      dispatch(GetAllSettingsAction());
    }
  }, [dispatch]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => file.size <= STORY_MAX_FILE_SIZE);

    if (validFiles.length + selectedImages.length > (items?.postSettings?.storyPerPost ?? STORY_MAX_IMAGES)) {
      dispatch(
        openToast({
          type: "error",
          message: `You can only upload up to ${items?.postSettings?.storyPerPost ?? STORY_MAX_IMAGES} images.`,
        })
      );
      return;
    }

    if (files.length !== validFiles.length) {
      dispatch(
        openToast({
          type: "error",
          message:
            "Some files were skipped because they exceed the 30MB size limit.",
        })
      );
    }

    Promise.all(
      validFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    ).then((results) => {
      setSelectedImages((prev) => [...prev, ...results]);
    });
  };

  const openImageUploadDialog = () => {
    fileInputRef.current.click();
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openCamera = () => {
    setIsCameraOpen(true);
  };

  const handlePost = () => {
    createPosts({ postType: "story" }, selectedImages)
      .then(() => {
        setSelectedImages([]);
        dispatch(getStoriesAction());
      })
      .catch(() => {
        dispatch(
          openToast({
            type: "error",
            message: "Ops... Something went wrong while creating the story.",
          })
        );
      });
  };

  const handleCapturePhoto = (photo) => {
    setSelectedImages((prev) => [...prev, photo]);
    setIsCameraOpen(false);
  };

  return (
    <>
      <div>
        <div className="blue-bg cursor-pointer" onClick={handleMenuClick}>
          <img src={PlusIcon} alt="Plus Icon" />
        </div>
      </div>

      <MediaMenuOptions
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        openImageUploadDialog={openImageUploadDialog}
        openCamera={openCamera}
      />

      <CameraPhotoCaptureDialog
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
        onCapturePhoto={handleCapturePhoto}
      />

      <input
        ref={fileInputRef}
        type="file"
        accept={acceptedFileTypes(imageMimeTypes)}
        onChange={handleImageUpload}
        className="hidden"
      />

      {selectedImages.length > 0 && !isCameraOpen && (
        <StoryModal
          username={me?.username || "You"}
          date="Now"
          profileImage={me?.profileImage ? `${process.env.REACT_APP_AWS_URL}${me?.profileImage}` : BlankUser}
          handleCancel={() => setSelectedImages([])}
          ProgressBarComponent={<ProgressBar />}
        >
          <div className="flex-grow overflow-hidden relative">
            <StoryContent images={selectedImages} currentIndex={currentIndex} />
            {selectedImages.length > 1 && (
              <>
                <ImageSwitcher
                  totalImages={selectedImages.length}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                />
                <NavigationArrows
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  totalImages={selectedImages.length}
                />
                <ImageCounter
                  currentIndex={currentIndex}
                  totalImages={selectedImages.length}
                />
              </>
            )}
          </div>
          <ActionButtons
            onPost={handlePost}
            onAddMore={handleMenuClick}
            canAddMore={selectedImages.length < (items?.postSettings?.storyPerPost ?? STORY_MAX_IMAGES)}
          />
        </StoryModal>
      )}
    </>
  );
};

export default StoryCreator;
