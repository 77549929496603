import React, { useEffect } from "react";
import "./assets/styles/darkmode.scss";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import "@fontsource/poppins";
import "./assets/scss/themes.scss";
import ToastWrapper from "./newComponents/atoms/ToastWrapper";
import { AdminModal, Modal, ThemeProvider } from "./newComponents/atoms";
import WebNavigation from "./navigation/webNavigation";
import AdminNavigation from "./navigation/adminNavigation";
import CustomizableModalContainer from "./newComponents/molecules/web/common/CustomizableModalContainer";
import UnifiedNavigation from "./navigation/unifiedNavigation";
import { useNotification } from './hooks/useNotification';
import { WebSocketProvider } from './contexts/WebSocketProvider';
import { useDispatch } from 'react-redux';
import { checkStoredAuthAction } from './store/middlewares/auth/session/checkAuth';
import TwoFactorNavigator from './components/auth/TwoFactorNavigator';

function App() {
  const dispatch = useDispatch();
  
  // init notifications
  useNotification();

  useEffect(() => {
    dispatch(checkStoredAuthAction());
  }, [dispatch]);

  return (
    <WebSocketProvider>
      <TwoFactorNavigator />
      <Modal />
      <AdminModal />
      <ToastWrapper />
      <CustomizableModalContainer />
      <Routes>
        <Route
          path="/*"
          element={
            <ThemeProvider>
              <UnifiedNavigation />
            </ThemeProvider>
          }
        />
        {/* <Route
          path="/user/*"
          element={
            <ThemeProvider>
              <UserNavigation />
            </ThemeProvider>
          }
        />
        <Route
          path="/creator/*"
          element={
            <ThemeProvider>
              <CreatorNavigation />
            </ThemeProvider>
          }
        /> */}
        <Route
          path="/*"
          element={
            <ThemeProvider>
              <WebNavigation />
            </ThemeProvider>
          }
        />
        <Route path="/admin/*" element={<AdminNavigation />} />
      </Routes>
    </WebSocketProvider>
  );
};

export default App;
