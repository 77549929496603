import React from "react";

export const TrashIcon = ({
  color = undefined,
  size = 20,
  type = "outline", //filled, outline
}) => {
  if (type === "outline") {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 5.48307C14.725 5.20807 11.9333 5.06641 9.15 5.06641C7.5 5.06641 5.85 5.14974 4.2 5.31641L2.5 5.48307"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.08594 4.64199L7.26927 3.55033C7.4026 2.75866 7.5026 2.16699 8.91094 2.16699H11.0943C12.5026 2.16699 12.6109 2.79199 12.7359 3.55866L12.9193 4.64199"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7057 8.11621L15.1641 16.5079C15.0724 17.8162 14.9974 18.8329 12.6724 18.8329H7.3224C4.9974 18.8329 4.9224 17.8162 4.83073 16.5079L4.28906 8.11621"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.60938 14.25H11.3844"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.91406 10.917H12.0807"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 5.48307C14.725 5.20807 11.9333 5.06641 9.15 5.06641C7.5 5.06641 5.85 5.14974 4.2 5.31641L2.5 5.48307"
          fill={color ?? "#292D32"}
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.08594 4.64199L7.26927 3.55033C7.4026 2.75866 7.5026 2.16699 8.91094 2.16699H11.0943C12.5026 2.16699 12.6109 2.79199 12.7359 3.55866L12.9193 4.64199"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7057 8.11621L15.1641 16.5079C15.0724 17.8162 14.9974 18.8329 12.6724 18.8329H7.3224C4.9974 18.8329 4.9224 17.8162 4.83073 16.5079L4.28906 8.11621"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.60938 14.25H11.3844"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.91406 10.917H12.0807"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};
