import React from "react";
import BookMarksIcon from "../assets/SVG/SideBar/BookMarksIcon";
import HomeIcon from "../assets/SVG/SideBar/HomeIcon";
import MessagesIcon from "../assets/SVG/SideBar/MessagesIcon";
import MoreIcon from "../assets/SVG/SideBar/MoreIcon";
import MyProfileIcon from "../assets/SVG/SideBar/MyProfileIcon";
import NotificationIcon from "../assets/SVG/SideBar/NotificationIcon";
import UserListsIcon from "../assets/SVG/SideBar/UserListsIcon";
import WishRequestsIcon from "../assets/SVG/SideBar/WishRequestsIcon";
import MyOrderIcon from "../assets/SVG/SideBar/MyOrderIcon";
import ExplorePageIcon from "../assets/SVG/SideBar/ExplorePageIcon";
import { returnLanguage } from "./languages";

// Import SVG files directly
import ShopIcon from "../assets/images/sidebar/shop.svg";
import PostLabelIcon from "../assets/images/sidebar/post-label.svg";
import VaultIcon from "../assets/images/sidebar/vault.svg";
import QueueIcon from "../assets/images/sidebar/queue.svg";
import StatementIcon from "../assets/images/sidebar/statements.svg";
import StasticsIcon from "../assets/images/sidebar/stastics.svg";

const iconMap = {
  "home": <HomeIcon />,
  "explore": <ExplorePageIcon />,
  "notifications": <NotificationIcon />,
  "wish-requests": <WishRequestsIcon />,
  "all-order": <MyOrderIcon />,
  "my-creator-shop": <img src={ShopIcon} alt="Shop" />,
  "messages": <MessagesIcon />,
  "user-lists": <UserListsIcon />,
  "bookmarks": <BookMarksIcon />,
  "post-label": <img src={PostLabelIcon} alt="Post Labels" />,
  "vault": <img src={VaultIcon} alt="Vault" />,
  "queue": <img src={QueueIcon} alt="Queue" />,
  "archive": <img src={VaultIcon} alt="Archive" />,
  "statements": <img src={StatementIcon} alt="Statements" />,
  "statistics": <img src={StasticsIcon} alt="Statistics" />,
  "my-profile": <MyProfileIcon />,
  "more": <MoreIcon />,
};

const creatorSideBarItems = (lang = "en") => {
  const langObj = returnLanguage(lang);

  const items = [
    { slug: "home", label: langObj?.Home, count: 0 },
    { slug: "explore", label: langObj?.Explore || "Explore", count: 0 },
    { slug: "notifications", label: langObj?.Notification, count: 3 },
    { slug: "wish-requests", label: langObj?.WishRequest, count: 3 },
    { slug: "all-order", label: langObj?.AllOrder, count: 3 },
    { slug: "my-creator-shop", label: langObj?.MyCreatorShop, count: 2 },
    { slug: "messages", label: langObj?.Messages, count: 0 },
    { slug: "user-lists", label: langObj?.UserLists, count: 0 },
    { slug: "bookmarks", label: langObj?.Bookmarks, count: 0 },
    { slug: "post-label", label: langObj?.PostLabels, count: 0 },
    { slug: "vault", label: langObj?.Vault, count: 0 },
    { slug: "queue", label: langObj?.Queue, count: 0 },
    { slug: "archive", label: "Archive", count: 0 },
    { slug: "statements", label: langObj?.Statements, count: 0 },
    { slug: "statistics", label: langObj?.Statistics, count: 0 },
    { slug: "my-profile", label: langObj?.MyProfile, count: 0 },
    { slug: "more", label: langObj?.More, count: 0 },
    // { slug: "saved-for-later", label: 'Saved For Later', count: 0 },
  ];

  return items.map((item) => ({
    ...item,
    icon: iconMap[item.slug], // Automatsko povezivanje ikone
    hasPage: true,
  }));
};

export default creatorSideBarItems;
