import React, { useEffect, useState } from "react";
import ProfileBg from "./../../../../../assets/images/profile-bg.png";
import ProfileUser from "./../../../../../assets/images/blank.png";
import UploadFill from "./../../../../../assets/images/upload-FILL.svg";
import ProfileCamera from "./../../../../../assets/images/camera.svg";
import Subscription from "./../../../../../assets/images/subscription-user.svg";
import ArrowLeftIcon from "./../../../../../assets/images/arrowleft.svg";
import Privacy from "./../../../../../assets/images/privacy.svg";
import { Grid, InputAdornment, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ModalProfilePrivacy } from "../../../../molecules";
import { Input } from "../../../../atoms";
import { openModal } from "../../../../../store/slices/common/modal";

const EditProfile = ({
  formik,
  fileSizeError,
  setFileSizeError,
  setFileUpdate,
  setProfileUpdate,
  onTabChange,
  isCreator = false,
}) => {
  const { me, meLoading } = useSelector((state) => state.me);
  const [file, setFile] = useState(null);
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setFile(
      me?.coverUrl
        ? `${me?.coverUrl}`
        : me?.coverImg
          ? // eslint-disable-next-line no-undef
          `${process.env.REACT_APP_AWS_URL}${me?.coverImg}`
          : null
    );
    setProfile(
      me?.profileUrl
        ? `${me?.profileUrl}`
        : me?.profileImage
          ? // eslint-disable-next-line no-undef
          `${process.env.REACT_APP_AWS_URL}${me?.profileImage}`
          : null
    );
  }, [me]);

  const handleClick = (tab) => {
    onTabChange(tab);
  };

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const fileSizeLimit = 3 * 1024 * 1024;
    if (file.size > fileSizeLimit) {
      setFileSizeError({
        ...fileSizeError,
        profile: "Plaese Upload a Profile Image having size less than 3MB",
      });
    } else {
      if (fileSizeError && Object.keys(fileSizeError).length > 0) {
        let tempObj = JSON.parse(JSON.stringify(fileSizeError));
        delete tempObj["profile"];
        setFileSizeError(tempObj);
      }
      reader.onload = () => {
        setProfile(reader.result);
        setProfileUpdate(file);
      };
      reader?.readAsDataURL(file);
    }
  };

  const handleCoverChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      const fileSizeLimit = 3 * 1024 * 1024;
      if (file.size > fileSizeLimit) {
        setFileSizeError({
          ...fileSizeError,
          cover: "Please Upload a file less than 3MB in size",
        });
      } else {
        if (fileSizeError && Object.keys(fileSizeError).length > 0) {
          let tempObj = JSON.parse(JSON.stringify(fileSizeError));
          delete tempObj["cover"];
          setFileSizeError(tempObj);
        }
        reader.onload = () => {
          setFile(reader.result);
          setFileUpdate(file);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleOpen = () => {
    dispatch(
      openModal({
        title: "PRIVACY AND SAFETY",
        content: <ModalProfilePrivacy />,
        hasCloseBtn: true,
      })
    );
  };

  console.log("me : ", me);

  return (
    <Grid container spacing={2} className="h-full fans-profile mt-3">
      <Grid item xs={12} md={12} lg={6}>
        <div className="lg:pr-3 post-no-padding">
          <div className="bg-white rounded-2xl py-3 px-5">
            <h3 className="font-semibold text-darkblue font-24 pb-2">
              Edit Profile
            </h3>
            <div className="relative cover-image">
              <div className="relative h-[200px]">
                <img
                  src={file ? file : ProfileBg}
                  alt="profile background"
                  id="cover"
                  className="w-full h-full object-cover"
                />
                <input
                  type="file"
                  id="myfile"
                  accept="image/png, image/jpeg, image/png, image/webp, image/bmp"
                  name="myfile"
                  className="input-cover-file img-dark-filter"
                  onChange={(e) => {
                    if (e.target.files) {
                      handleCoverChange(e);
                    } else {
                      setFileUpdate(null);
                    }
                  }}
                />
                <label
                  htmlFor="myfile"
                  className="flex items-center justify-center profile-text"
                >
                  {!file && (
                    <>
                      {" "}
                      <img
                        src={UploadFill}
                        alt="upload-cover"
                        className="mr-2"
                      />
                      <div>
                        {" "}
                        <p style={{ color: "#2799F6", width: '170px' }}>Upload Cover Image</p>
                      </div>
                    </>
                  )}
                </label>
              </div>
              <div className="relative lg:top-[-64px] top-[-30px] lg:left-[32px] left-[10px] ">
                <div className="relative w-16 lg:w-32 h-16 lg:h-32 ">
                  <img
                    src={profile ? profile : ProfileUser}
                    alt="Profile User"
                    className={"w-full h-full rounded-full object-cover border-white border-4 shadow-lg"}
                  />
                  <input
                    type="file"
                    id="profile"
                    accept="image/png, image/jpeg, image/png, image/webp, image/bmp"
                    name="myfile"
                    className="input-profile-file rounded-full"
                    onChange={(e) => {
                      if (e.target.files) {
                        handleProfileChange(e);
                      } else {
                        setProfileUpdate(null);
                      }
                    }}
                    hidden
                  />
                  <label
                    htmlFor="profile"
                    className="camera-btn lg:bottom-0 -bottom-[10px] lg:-right-[0] -right-[10px]"
                  >
                    <img
                      src={ProfileCamera}
                      alt="profile-camera"
                      className={"w-full h-full rounded-full object-cover"}
                    />
                  </label>
                </div>
              </div>
            </div>
            {fileSizeError &&
              Object.keys(fileSizeError).map((error) => {
                return (
                  <p className="text-red-500" key={error}>
                    {fileSizeError[`${error}`]}
                  </p>
                );
              })}
            <form>
              <div className="mb-6 w-full">
                {meLoading ? (
                  <Skeleton
                    variant="rounded"
                    animation="pulse"
                    style={{ borderRadius: "16px" }}
                    width={602}
                    height={66}
                  />
                ) : (
                  <Input
                    name="username"
                    value={formik.values.username ?? ""}
                    disabled={me?.userType !== 'creator'}
                    onChange={formik.handleChange}
                    placeholder="Username"
                    onBlur={formik.handleBlur}
                    errorMsg={formik.errors.username}
                    id="username"
                    error={formik.errors.username && formik.touched.username}
                    startAdornment="@"
                    sx={{
                      '& .MuiInputAdornment-root': {
                        marginRight: '0px',
                        '& p': {
                          marginRight: '-10px'
                        }
                      },
                      '& .MuiInputBase-input': {
                        marginLeft: '-1px'
                      }
                    }}
                  />
                )}
              </div>
              <div className="mb-6 w-full">
                {meLoading ? (
                  <Skeleton
                    variant="rounded"
                    animation="pulse"
                    style={{ borderRadius: "16px" }}
                    width={602}
                    height={66}
                  />
                ) : (
                  <Input
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    placeholder="Enter Name"
                    id="name"
                    inputProps={{ maxLength: 40 }}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        {formik.values.name.length}/40
                      </InputAdornment>
                    }
                    error={formik.errors.name && formik.touched.name}
                    errorMsg={formik.errors.name}
                  />
                )}
              </div>
              <div className="mb-6 w-full">
                {meLoading ? (
                  <Skeleton
                    variant="rounded"
                    animation="pulse"
                    style={{ borderRadius: "16px" }}
                    width={602}
                    height={66}
                  />
                ) : (
                  <Input
                    name="bio"
                    value={formik.values.bio}
                    placeholder="Enter Bio"
                    id="bio"
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 1000 }}
                    multiline
                    errorMsg={formik.errors.bio}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        {formik.values.bio.length}/10000
                      </InputAdornment>
                    }
                    error={formik.errors.bio && formik.touched.bio}
                  />
                )}
              </div>
              <div className="mb-6 w-full">
                {meLoading ? (
                  <Skeleton
                    variant="rounded"
                    animation="pulse"
                    style={{ borderRadius: "16px" }}
                    width={602}
                    height={66}
                  />
                ) : (
                  <Input
                    name="city"
                    value={formik.values.city}
                    placeholder="Enter City"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="city"
                    errorMsg={formik.errors.city}
                    inputProps={{ maxLength: 64 }}
                    endAdornment={
                      <InputAdornment position="end">
                        {formik.values.city.length}/64
                      </InputAdornment>
                    }
                    error={formik.errors.city && formik.touched.city}
                  />
                )}
              </div>
              {isCreator && (
                <>
                  <div className="mb-6 w-full">
                    {meLoading ? (
                      <Skeleton
                        variant="rounded"
                        animation="pulse"
                        style={{ borderRadius: "16px" }}
                        width={602}
                        height={66}
                      />
                    ) : (
                      <Input
                        name="website"
                        value={formik.values.website}
                        placeholder="Enter Website"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="website"
                        errorMsg={formik.errors.website}
                        inputProps={{ maxLength: 100 }}
                        endAdornment={
                          <InputAdornment position="end">
                            {formik.values.website?.length}/100
                          </InputAdornment>
                        }
                        error={formik.errors.website && formik.touched.website}
                      />
                    )}
                  </div>
                  <div className="mb-6 w-full">
                    {meLoading ? (
                      <Skeleton
                        variant="rounded"
                        animation="pulse"
                        style={{ borderRadius: "16px" }}
                        width={602}
                        height={66}
                      />
                    ) : (
                      <Input
                        name="amazonList"
                        value={formik.values.amazonList}
                        placeholder="Enter Amazon Wishlist"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{ maxLength: 100 }}
                        endAdornment={
                          <InputAdornment position="end">
                            {formik.values.amazonList?.length}/100
                          </InputAdornment>
                        }
                        id="amazonList"
                        errorMsg={formik.errors.amazonList}
                        error={
                          formik.errors.amazonList && formik.touched.amazonList
                        }
                      />
                    )}
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={12} lg={6}>
        <div className="lg:pl-3 post-no-padding">
          <div className="bg-white rounded-2xl p-4">
            {me?.userType === 'creator' && <div className="gray-border p-4 border-radius-10">
              <h3 className="text-darkblue font-20 font-medium mb-3">
                Subscription
              </h3>
              <div
                className="cursor-pointer lightgray-400 p-3 flex justify-between border-radius-10"
                onClick={() => handleClick("Subscription")}
              >
                <div className="flex gap-3">
                  <img src={Subscription} alt="Subscription" />
                  <p className="font-18 font-medium text-darkblue ">
                    Subscription Price and Bundles
                  </p>
                </div>
                <img src={ArrowLeftIcon} alt="ArrowLeft" className="img-dark-filter" />
              </div>
            </div>}

            {/* <div className="gray-border p-4 border-radius-10 mt-4">
              <h3 className="text-darkblue font-20 font-medium mb-3">
                Additional Info
              </h3>
              <div
                className="cursor-pointer lightgray-400 p-3 flex justify-between border-radius-10"
                onClick={() => {
                  if (!me?.spotify?.displayName) {
                    handleClick("Spotify");
                  }
                }}
              >
                <div className="flex gap-3">
                  <img src={Sopify} alt="Sopify" />
                  <p className="font-18 font-medium text-darkblue">
                    Spotify Account
                  </p>
                </div>
                <img src={ArrowLeftIcon} alt="ArrowLeft" className="img-dark-filter" />
              </div>
            </div> */}

            {/* <div className="gray-border p-4 border-radius-10 mt-4">
              <h3 className="text-darkblue font-20 font-medium mb-3">Web 3</h3>
              <div
                className="cursor-pointer lightgray-400 p-3 flex justify-between border-radius-10"
                onClick={() => handleClick("Web3")}
              >
                <div className="flex gap-3">
                  <img src={Web3Avatar} alt="Sopify" />
                  <p className="font-18 font-medium text-darkblue ">
                    Web3 Avatar
                  </p>
                </div>
                <img src={ArrowLeftIcon} alt="ArrowLeft" className="img-dark-filter" />
              </div>

              <div
                className="cursor-pointer lightgray-400 p-3 flex justify-between border-radius-10 mt-3"
                onClick={() => handleClick("NETs")}
              >
                <div className="flex gap-3">
                  <img src={Nets} alt="Nets" />
                  <p className="font-18 font-medium text-darkblue ">NETs</p>
                </div>
                <img src={ArrowLeftIcon} alt="ArrowLeft" className="img-dark-filter" />
              </div>
            </div> */}

            <div className="gray-border p-4 border-radius-10 mt-4">
              <h3 className="text-darkblue font-20 font-medium mb-3">
                Related Settings
              </h3>
              <div
                className="cursor-pointer lightgray-400 p-3 flex justify-between border-radius-10"
                onClick={() => handleOpen()}
              >
                <div className="flex gap-3">
                  <img src={Privacy} alt="Privacy" />
                  <p className="font-18 font-medium text-darkblue ">
                    Privacy and Safety
                  </p>
                </div>
                <img src={ArrowLeftIcon} alt="ArrowLeft" className="img-dark-filter" />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default EditProfile;
