import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMessages, getMessageThread, sendMessage, deleteMessage } from "../../../services/web/messages/messages.services";

export const getMessagesAction = createAsyncThunk(
  "messages/getMessages",
  async ({ cursor } = {}, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const filter = state.messages.filter;
      const response = await getMessages(filter, cursor);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch messages");
    }
  }
);

export const getMessageThreadAction = createAsyncThunk(
  "messages/getMessageThread",
  async ({ chatId, cursor } = {}, { rejectWithValue }) => {
    try {
      const response = await getMessageThread(chatId, cursor);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch message thread");
    }
  }
);

export const sendMessageAction = createAsyncThunk(
  "messages/sendMessage",
  async (messageData, { rejectWithValue }) => {
    try {
      const response = await sendMessage(messageData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to send message");
    }
  }
);

export const deleteMessageAction = createAsyncThunk(
  "messages/deleteMessage",
  async (messageId, { rejectWithValue }) => {
    try {
      const response = await deleteMessage(messageId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to delete message");
    }
  }
); 