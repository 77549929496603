import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import Logo from "../../../../../assets/images/logo.svg";
import { closeModal } from "../../../../../store/slices/common/modal";

const ModalInvalidVerification = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <div className="flex justify-center items-center pb-3 pt-5">
        <div>
          <div className="flex justify-center items-center pb-7">
            <img src={Logo} alt="Logo" className="w-60" />
          </div>
          <h3 className="text-lg text-center text-[#1E405C] font-semibold pb-3">
            Invalid Verification Token
          </h3>
          <span className="block text-center">
            The verification token is invalid. Please try again or contact support.
          </span>
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <Button
          variant="contained"
          className="m-auto px-12"
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default ModalInvalidVerification; 