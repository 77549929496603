import axiosClient from "../../../../utils/api";

export const getVerificationRequests = async (params) => {
  try {
    const response = await axiosClient.get("/admin/CreatorRequest/ondato-requests", {
      params,
    });
    return response.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};
